type Props = {
  name: string;
  errors: any;
  touched: any;
};
const ErrorMessage = ({ name, errors, touched }: Props) => {
  if (errors && typeof errors[name] === "string" && touched[name]) {
    return (
      <p
        color="error"
        style={{ fontSize: "12px", padding: "0", color: "red", margin: "0" }}
      >
        {errors[name]}
      </p>
    );
  }
  return null;
};
export default ErrorMessage;
