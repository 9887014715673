import * as React from 'react';
const SvgSupport = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="none"
    viewBox='0 0 12 13'
    {...props}
  >
    <path
      fill="#333"
      d="M3.824 0a1.53 1.53 0 0 0-1.53 1.53V5.51q.376-.106.765-.141V1.53a.765.765 0 0 1 .765-.765h3.058V3.44A1.147 1.147 0 0 0 8.03 4.588h2.677v6.118a.765.765 0 0 1-.765.765H7.188q-.209.409-.502.764H9.94a1.53 1.53 0 0 0 1.53-1.53V4.14c0-.304-.122-.596-.337-.81L8.141.336A1.15 1.15 0 0 0 7.331 0zm6.724 3.824H8.028a.38.38 0 0 1-.382-.383V.923zM6.881 9.559a3.441 3.441 0 1 0-6.882 0 3.441 3.441 0 0 0 6.882 0m-3.44 1.438a.478.478 0 1 1 0 .955.478.478 0 0 1 0-.956m0-3.73c.788 0 1.416.647 1.416 1.491 0 .448-.163.695-.556 1.009l-.212.163c-.188.149-.251.23-.264.343l-.009.12a.382.382 0 0 1-.758-.07c0-.435.16-.675.547-.984l.213-.165c.22-.176.275-.261.275-.416 0-.427-.292-.726-.653-.726-.378 0-.657.28-.652.722a.383.383 0 0 1-.765.008c-.008-.87.616-1.495 1.417-1.495"
    />
  </svg>
);
export default SvgSupport;