import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { applicationapi } from "../../api";





// Define the shape of the account data
interface AccountData {
  organisationName: string;
  organisationID: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface AccountSettingState {
  name: string;
  isLoading: boolean;
  isError: boolean;
  data: AccountData;
}
interface AccountUpdateData {
  firstName: string;
  lastName: string;
}

// Define the initial state
const initialState: AccountSettingState = {
  name: "AccountSetting",
  isLoading: false,
  isError: false,
  data: {
    organisationName: "",
    firstName: "",
    email: "",
    organisationID: "",
    lastName: "",
  },
};

// Create the async thunk with a specific return type
export const getAccountSettingData = createAsyncThunk<AccountData>(
  "getAccountSettingData",
  async (_, thunkAPI) => {
    const idToken = sessionStorage.getItem("id_token");
    const url="/account_settings"
    try {
      const response = await applicationapi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data.response;
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({
              message: "Incorrect Tocken. Please try again.",
              status: 401,
            });
          case 404:
            return thunkAPI.rejectWithValue({
              message: "Resource not found.",
              status: 404,
            });
          case 500:
            return thunkAPI.rejectWithValue({
              message: "Internal server error. Please try again later.",
              status: 500,
            });
          default:
            return thunkAPI.rejectWithValue({
              message:
                error.response?.data?.data?.message || "Failed to fetch data",
              status: error.response.status,
            });
        }
      } else {
        return thunkAPI.rejectWithValue({
          message: "Network error or server did not respond.",
          status: null,
        });
      }
    }
  }
);

export const updateAccountSettingData = createAsyncThunk<
  AccountUpdateData,
  FormData
  >("updateAccountSettingData", async (formData, thunkAPI) => {
    const url = "/account_settings"
      const idToken = sessionStorage.getItem("id_token");
  try {
    const response = await applicationapi.put(
      url,
      formData,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          return thunkAPI.rejectWithValue({
            message: "Incorrect Tocken. Please try again.",
            status: 401,
          });
        case 404:
          return thunkAPI.rejectWithValue({
            message: "Resource not found.",
            status: 404,
          });
        case 500:
          return thunkAPI.rejectWithValue({
            message: "Internal server error. Please try again later.",
            status: 500,
          });
        default:
          return thunkAPI.rejectWithValue({
            message:
              error.response?.data?.data?.message || "Failed to fetch data",
            status: error.response.status,
          });
      }
    } else {
      return thunkAPI.rejectWithValue({
        message: "Network error or server did not respond.",
        status: null,
      });
    }
  }
});

// Create the slice
const accountSettingSlice = createSlice({
  name: "AccountSetting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountSettingData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getAccountSettingData.fulfilled,
        (state, action: PayloadAction<AccountData>) => {
          state.isLoading = false;
          state.data = action.payload; // Ensure payload matches state.data type
        }
      )
      .addCase(getAccountSettingData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateAccountSettingData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateAccountSettingData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.data.firstName = action.payload.data.response.firstName;
          state.data.lastName = action.payload.data.response.lastName;
        }
      )
      .addCase(updateAccountSettingData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default accountSettingSlice.reducer;
