
const SvgDownloadInvoice = (props: any) => (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="#581845"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill={props.fill}
            fillRule="evenodd"
            d="M1.5 12C1.0875 12 0.734251 11.853 0.440251 11.559C0.146251 11.265 -0.000498727 10.912 1.27335e-06 10.5V8.25H1.5V10.5H10.5V8.25H12V10.5C12 10.9125 11.853 11.2657 11.559 11.5597C11.265 11.8537 10.912 12.0005 10.5 12H1.5ZM6 9L2.25 5.25L3.3 4.1625L5.25 6.1125V0H6.75V6.1125L8.7 4.1625L9.75 5.25L6 9Z" />
    </svg>

);
export default SvgDownloadInvoice;




