import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Signup/signup.module.css";
import SvgZita from "../../icons/SvgZita";
import Button from "../../uikit/Button";
import { verifyToken } from "../../configuration";

const Verificationsucess = () => {
  const version = process.env.REACT_APP_VERSION;
  const navigate = useNavigate();
  const [overallloader, setoverallloader] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(15);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsExpired(true);
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    setoverallloader(true);
    const handleVerifyTokens = async () => {
      const accessToken = sessionStorage.getItem("access_token");
      const idToken = sessionStorage.getItem("id_token");

      if (accessToken && idToken) {
        try {
          const accessTokenPayload = await verifyToken(accessToken, "access");
          const idTokenPayload = await verifyToken(idToken, "id");
          if (accessTokenPayload && idTokenPayload) {
            navigate("/overview");
            setoverallloader(false);
          } else {
            navigate("/verification-success");
            setoverallloader(false);
          }
        } catch (err) {
          console.error("Error verifying tokens:", err);
          navigate("/verification-success");
          setoverallloader(false);
        }
      } else {
        console.error("No access token or ID token found in session storage.");
        navigate("/verification-success");
        setoverallloader(false);
      }
    };
    handleVerifyTokens();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
      sessionStorage.removeItem("ver-email");
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div
        className={`container-fluid ${styles.bgimage}`}
        style={{
          backgroundImage: `url(/Images/loginbgimage.png)`,
          fontSize: "13px",
          height: window.innerHeight,
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{ minHeight: "80vh" }}
        >
          <div
            className="text-center"
            style={{ marginTop: "3%", width: "500px" }}
          >
            <SvgZita width={150} height={80} />
            <div style={{ marginTop: "10%" }}>
              <div>
                <h4 className="fw-bold" style={{ marginBottom: "35px" }}>
                  Congratulations!
                </h4>
                <p className="text-center" style={{ marginBottom: "10px" }}>
                  Your account has been <strong>successfully verified!</strong>
                </p>
                <p style={{ margin: "10px" }}>Redirecting to your sign in...</p>
                <p style={{ fontWeight: "700" }}>
                  {!isExpired
                    ? `0:${String(timeLeft).padStart(2, "0")}`
                    : "0:00"}
                </p>
                <p style={{ margin: "10px" }}>
                  Check your email for a <strong>Free Promo Code</strong> we’ve
                  sent you!{" "}
                </p>
                <p style={{ marginTop: "0px" }}>
                  Redeem it to enjoy exclusive benefits on any of our API
                  products.
                </p>
                <div>
                  <p style={{ marginTop: "15px" }}>
                    <strong>Need help?</strong> Contact us at{" "}
                    <a
                      href="mailto:support@zita.ai?subject=Help Needed"
                      className="fw-bold "
                      style={{
                        color: "#581845",
                        textDecoration: "none",
                      }}
                    >
                      support@zita.ai.
                    </a>
                  </p>
                </div>
                <div>
                  <p style={{ fontWeight: "700", marginTop: "15px" }}>
                    Happy exploring!
                  </p>
                </div>
                <div style={{ marginTop: "25px" }}>
                  <button
                    className={`btn ${styles.successbtn}`}
                    type="button"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <footer className={`text-center ${styles.rights}`}>
            <div>App Version {version}</div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Verificationsucess;
