import React, { useState, useEffect, useRef } from "react";
import SvgZita from "../../icons/SvgZita";
import SvgNotification from "../../icons/SvgNotification";
import SvgHelpIcon from "../../icons/SvgHelpIcon";
import SvgUserProfile from "../../icons/SvgUserProfile";
import styles from "./navbar.module.css";
import SvgSignOut from "../../icons/SvgSignOut";
import SvgAccSettings from "../../icons/SvgAccSettings";
import SvgAuthenticate from "../../icons/SvgAuthenticate";
import Notification from "../Notification/Notification";
import SvgSupport from "../../icons/SvgSupport";
import SvgDocument from "../../icons/SvgDocument";
import { Notificationapi, Signoutapi } from "../../api";
import SvgLock from "../../icons/SvgLock";
import { Link, useNavigate } from "react-router-dom";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import Loader from "../../uikit/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store/store";
import { getAccountSettingData } from "../../redux/reducers/AccountSettingSlice";

const Navbar = () => {
  const navigateTo = useNavigate();
  const [isAccountHovered, setAccountHovered] = useState(false);
  const [isAuthentHovered, setAuthentHovered] = useState(false);
  const [isPasswordHovered, setPasswordHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setloading] = useState<boolean>(false);

  const [apiValues, setApivalues] = useState<any>([]);
  const [isPageLoader, setPageLoader] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  const user_name = sessionStorage.getItem("user_name") || "";

  const navigate = useNavigate();
  const helpRef = useRef<HTMLDivElement | null>(null);
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const userMenuRef = useRef<HTMLDivElement | null>(null);

  const [dropdown, setDropdown] = useState({
    help: false,
    notification: false,
    userMenu: false,
  });

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await Notificationapi();
      setApivalues(response.data.data);
    };
    fetchNotifications();
  }, []);

  const { data } = useSelector((state: RootState) => state.accountSetting);
  const dispatch = useDispatch<AppDispatch>();

  let user: any;

  if (data?.firstName) {
    user = data?.firstName + " " + data?.lastName;
  }
  if (!data?.firstName) {
    user = user_name;
  }

  useEffect(() => {
    if (!data?.firstName) {
      dispatch(getAccountSettingData());
    } else {
      user = user_name;
    }
  }, [user, user_name, dispatch]);

  const handleSignOut = () => {
    setloading(true);
    Signoutapi(email)
      .then((res) => {
       // console.log("response", res.data.data);
        sessionStorage.clear();
        navigate("/");
        setloading(false);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
         // console.log("dataaaa", error.response.data.data.message);
          setloading(false);
        }
      });
  };

  const handleHelp = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDropdown((prevState) => ({
      help: !prevState.help,
      notification: false,
      userMenu: false,
    }));
  };

  const handleNotification = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDropdown((prevState) => ({
      help: false,
      notification: !prevState.notification,
      userMenu: false,
    }));
  };

  const handleUser = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDropdown((prevState) => ({
      help: false,
      notification: false,
      userMenu: !prevState.userMenu,
    }));
  };

  const handleDocument = () => {
    navigateTo("/documentation");
    setDropdown({
      help: false,
      notification: false,
      userMenu: false,
    });
  };

  const handleAccountSettings = () => {
    navigateTo("/account-setting");
    setDropdown({
      help: false,
      notification: false,
      userMenu: false,
    });
  };

  const handleAuthentication = () => {
    navigateTo("/authentication");
    setDropdown({
      help: false,
      notification: false,
      userMenu: false,
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      helpRef.current &&
      !helpRef.current.contains(event.target as Node) &&
      dropdown.help
    ) {
      setDropdown((prevState) => ({ ...prevState, help: false }));
    }

    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node) &&
      dropdown.notification
    ) {
      setDropdown((prevState) => ({ ...prevState, notification: false }));
    }

    if (
      userMenuRef.current &&
      !userMenuRef.current.contains(event.target as Node) &&
      dropdown.userMenu
    ) {
      setDropdown((prevState) => ({ ...prevState, userMenu: false }));
    }
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    if (dropdown.notification) {
      document.addEventListener("click", handleClick);
    }
    if (dropdown.help) {
      document.addEventListener("click", handleClick);
    }
    if (dropdown.userMenu) {
      document.addEventListener("click", handleClick);
    }
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [dropdown]);

  return (
    <>
      {(isLoading || isPageLoader) && (
        <div className={`${styles.pageLoader}`}>
          <Loader />
        </div>
      )}
      <div
        className={`${styles.navbarElement} d-flex flex-row justify-content-between align-items-center`}
      >
        <div className={`${styles.iconsFlex} position-relative`}>
          <div>
            <SvgZita />
          </div>

          <div className={`d-flex flex-row ${styles.dropdownIcons}`}>
            {/* Notification Icon */}

            <div className={`${styles.bellIcon}`}>
              <CustomTooltip title={"Notification"}>
                <div
                  className={`d-flex align-items-center ${styles.navNotification} position-relative`}
                  onClick={handleNotification}
                >
                  {apiValues.length !== 0 && apiValues?.total_unread !== 0 ? (
                    <div className={styles.unreadCountStyle}>
                      <span
                        style={{
                          fontSize: 10,
                          color: "white",
                          marginRight: "1px",
                        }}
                      >
                        {apiValues && apiValues.total_unread}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <SvgNotification />
                </div>
              </CustomTooltip>
              {dropdown.notification && (
                <div ref={notificationRef}>
                  <Notification
                    apiData={apiValues}
                    setApivalues={setApivalues}
                    setDropdown={setDropdown}
                    setPageLoader={setPageLoader}
                  />
                </div>
              )}
            </div>

            {/* Help Icon */}
            <div className={`${styles.navHelpIcon}`}>
              <CustomTooltip title={"Help"}>
                <div
                  className={`d-flex align-items-center ${styles.navHelp}`}
                  onClick={handleHelp}
                >
                  <SvgHelpIcon />
                </div>
              </CustomTooltip>
              {dropdown.help && (
                <>
                  <div
                    className={`d-flex flex-column align-items-center ${styles.helpDropdown}`}
                    ref={helpRef}
                  >
                    <ul className="p-0 m-0">
                      <li className={`nav-link ${styles.helpMenuName}`}>
                        <Link
                          target="_blank"
                          to="https://share.hsforms.com/1Q2EGifk_SwKKjLMaSpTfeQqmqrf"
                          className="nav-link d-flex"
                        >
                          <div className={styles.helpMenuIcon}>
                            <SvgSupport width={15} height={15} />
                          </div>
                          <div className={styles.helpMenuOption}>Support</div>
                        </Link>
                      </li>
                      <li className={`nav-link ${styles.helpMenuName}`}>
                        <div
                          onClick={() => handleDocument()}
                          className="nav-link d-flex"
                        >
                          <div className={styles.helpMenuIcon}>
                            <SvgDocument width={15} height={15} />
                          </div>
                          <div className={styles.helpMenuOption}>
                            Documentations
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center">
          {/* Split Line */}
          <div className={styles.splitLine} />

          {/* UserMenu Block */}
          <div
            className={`d-flex align-items-center ${styles.navbarUser}`}
            onClick={handleUser}
          >
            <div className={styles.navbarUserIcon}>
              <SvgUserProfile width={18} height={21} />
            </div>
            <CustomTooltip title={`${user}`}>
              <div className={`${styles.userNameText}`}>{user}</div>
            </CustomTooltip>
          </div>
        </div>
      </div>

      {dropdown.userMenu && (
        <>
          <div
            className={`d-flex flex-column align-items-center ${styles.userDropdown}`}
            ref={userMenuRef}
          >
            <ul className={styles.userMenuoptionSection}>
              <li
                onMouseEnter={() => setAccountHovered(true)}
                onMouseLeave={() => setAccountHovered(false)}
                className={`nav-link ${styles.userMenuName}`}
              >
                <div
                  onClick={() => handleAccountSettings()}
                  className="nav-link d-flex align-items-center"
                >
                  <div className={styles.userMenuIcon}>
                    <SvgAccSettings
                      width={17}
                      height={17}
                      fill={isAccountHovered ? "#581845" : ""}
                    />
                  </div>
                  <div style={{ marginTop: 2 }}>Account Setting</div>
                </div>
              </li>
              <li
                onMouseEnter={() => setAuthentHovered(true)}
                onMouseLeave={() => setAuthentHovered(false)}
                className={`nav-link ${styles.userMenuName}`}
              >
                <div
                  onClick={() => handleAuthentication()}
                  className="nav-link d-flex align-items-center"
                >
                  <div className={styles.userMenuIcon}>
                    <SvgAuthenticate
                      width={17}
                      height={17}
                      fill={isAuthentHovered ? "#581845" : ""}
                    />
                  </div>
                  <div style={{ marginTop: 2 }}>Authentication</div>
                </div>
              </li>
              <li
                onMouseEnter={() => setPasswordHovered(true)}
                onMouseLeave={() => setPasswordHovered(false)}
                onClick={() => {
                  setShowModal(true);
                  setDropdown((prevState) => ({ ...prevState, help: false }));
                  setDropdown((prevState) => ({ ...prevState, userMenu: false }));
                }}
                className={`nav-link ${styles.userMenuName}`}
              >
                <div className="nav-link d-flex align-items-center">
                  <div
                    className={styles.userMenuIcon}
                    style={{ marginBottom: "3px" }}
                  >
                    <SvgLock
                      width={17}
                      height={17}
                      stroke={isPasswordHovered ? "#581845" : ""}
                    />
                  </div>
                  <div style={{ marginTop: 2 }}>Change Password</div>
                </div>
              </li>
            </ul>
            <ul className={`m-0 ${styles.menuUserSignOut}`}>
              <li className="nav-link">
                {!isLoading ? (
                  <div
                    onClick={() => handleSignOut()}
                    className="nav-link d-flex align-items-center"
                  >
                    <div className="d-flex">
                      <SvgSignOut width={15} height={15} />
                    </div>
                    <div className={`d-flex ${styles.userSignOut}`}>
                      Sign Out
                    </div>
                  </div>
                ) : (
                  <div className="nav-link d-flex align-items-center">
                    <div className="d-flex">
                      <SvgSignOut width={15} height={15} />
                    </div>
                    <div className={`d-flex ${styles.userSignOut}`}>
                      Sign Out
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </>
      )}

      <div>
        <ChangePasswordModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </div>
    </>
  );
};

export default Navbar;
