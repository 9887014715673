import React, { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import Resume from "./resume";
import Comparative from "./comparativeDoc";
import JdGenerationDoc from "./JdgenerationDoc";
import ProfileDoc from "./ProfilesummaryDoc";
import InterviewDoc from "./InterviewQuestionDoc";
import MatchingDoc from "./matchingDoc";
import JdparseDoc from "./JdparserDoc";
import AllDoc from "./AllDoc";
import style from "./documentsidebar.module.css";
import yaml from "yaml";
interface DocumentData {
  name: string;
  description: string;
  url: string;
}
const specUrl = `/jd_generation.yaml`;
type Props = {
  view?: (value: boolean) => void;
  data?: any;
  index?: number;
  changeindex?: (value: number) => void;
};

const DocumentSidebar = ({ view, data, index, changeindex }: Props) => {
  const backdocument = () => {
    if (view) {
      view(false);
    }
  };

  const selectapi = (ind: number) => {
    if (changeindex) {
      changeindex(ind);
    }
  };
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    const handleFullscreenChange = () => setWindowHeight(window.innerHeight);

    window.addEventListener("resize", handleResize);
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  // useEffect(() => {
  //   document_print();
  // }, [index]);

  const name = () => {
    if (index !== undefined) {
      var name = data?.[index].name;
      var url = data?.[index].url;

      return name;
    }
  };

  var document_print = () => {
    if (index !== undefined) {
      var name = data?.[index].name;
      if (name === "Resume Parser") {
        // console.log("name", name);
        return <Resume />;
      } else if (name === "Comparative Analysis") {
        return <Comparative />;
      } else if (name === "AI JD Assistance") {
        return <JdGenerationDoc />;
      } else if (name === "AI Interview Question Generation") {
        return <InterviewDoc />;
      } else if (name === "JD Parser") {
        return <JdparseDoc />;
      } else if (name === "AI Matching Analysis") {
        return <MatchingDoc />;
      } else if (name === "Profile Summary") {
        return <ProfileDoc />;
      }
    }
  };
  //console.log("++++=", index);
  return (
    <>
      {/* <div style={{ paddingLeft: "40px", overflow: "hidden" }}>
        <span
          onClick={() => backdocument()}
          style={{
            marginRight: "2px",
            fontSize: "14px",
            fontWeight: 700,
            cursor: "pointer",
            color: "#581845",
          }}
        >
          {" "}
          Documentation
        </span>
      </div> */}

      <div
        style={{
          flex: "1",
          marginLeft: "15px",
          overflow: "scroll",
          height: windowHeight - 150,
        }}
      >
        <AllDoc />
      </div>
    </>
  );
};

export default DocumentSidebar;
