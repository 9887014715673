import React from "react";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import styles from "./apipurchaselog.module.css";
import SvgNoReport from "../../icons/SvgNoReport";
import moment from "moment";


interface DataItem {
  api_name: string;
  total_credit: number;
  credit_used: number;
  available_credit: number;
  payment_date: string;
  expired_date: string;
  addon_count: number;
}

interface ApiPurchaseLogProps {
  data: DataItem[];
}

const purchase_log = [
  {
    payment_date: "2024-09-25",
    expired_date: "25-10-2024",
    name: "Comparative Analysis",
    availablecredits: 110,
    creditsconsumed: 110,
    addoncredits: 110,
  },
  {
    payment_date: "2024-09-25",
    expired_date: "25-10-2024",
    name: "AI Matching Analysis",
    availablecredits: 110,
    creditsconsumed: 110,
    addoncredits: 110,
  },
  {
    payment_date: "2024-09-25",
    expired_date: "25-10-2024",
    name: "AI Interview Question Generation",
    availablecredits: 110,
    creditsconsumed: 110,
    addoncredits: 110,
  },
  {
    payment_date: "2024-09-25",
    expired_date: "25-10-2024",
    name: "Profile Summary",
    availablecredits: 110,
    creditsconsumed: 110,
    addoncredits: 110,
  },
  {
    payment_date: "2024-09-25",
    expired_date: "25-10-2024",
    name: "AI JD Assistance",
    availablecredits: 110,
    creditsconsumed: 110,
    addoncredits: 110,
  },
  {
    payment_date: "2024-09-25",
    expired_date: "25-10-2024",
    name: "JD Parser",
    availablecredits: 110,
    creditsconsumed: 110,
    addoncredits: 110,
  },
  {
    payment_date: "2024-09-25",
    expired_date: "25-10-2024",
    name: "Resume Parser",
    availablecredits: 110,
    creditsconsumed: 110,
    addoncredits: 110,
  },
];

const ApiPurchaseLog: React.FC<ApiPurchaseLogProps> = ({ data }) => {
  console.log("sssssssfinal", data);
  return (
    
    <div className="card">
      <div className="card-body" style={{ paddingLeft: "0px" }}>
      {data && data?.length === 0 ? (
                        <div
                            className="col-12 d-flex justify-content-center align-items-center flex-column"
                            style={{ height: "345px" }}
                        >
                            <div style={{ padding: '0 0 10px 0' }}>
                                <SvgNoReport />
                            </div>
                            <div style={{ color: '#999FAC', fontSize: '13px' }}>
                                Looks like there are no report details available.
                            </div>
                        </div>
                    ) : (
        <div className="">
          <div>
            <table
              className={`table mb-0 ${styles.table}`}
              style={{ height: "345px" }}
            >
              <thead className={styles.stickyHeader}>
                <tr>
                  <th
                    className={styles.headingtable}
                    style={{ paddingLeft: "15px",width:'25%' }}
                  >
                    API Product
                  </th>
                  <th className={styles.headingtable} style={{paddingLeft:'15px'}}>Credits Consumed</th>
                  <th className={styles.headingtable} style={{paddingLeft:'15px'}}>Avail Credits</th>
                  <th className={styles.headingtable} style={{paddingLeft:'15px'}}>Add-On Credits</th>
                  <th className={styles.headingtable} style={{width:'14%',paddingLeft:'15px'}}>Purchased On</th>
                  <th className={styles.headingtable} style={{width:"11%",paddingLeft:'15px'}}>Expires On</th>
                </tr>
              </thead>
              <tbody className={styles.tbody} style={{ paddingLeft: "10px" ,paddingRight:'8px'}}>
                {data && data?.map((item, index) => (
                  
                  <tr key={index} className={styles.card}>
                    
                    <td
                      className={`${styles.valuetable} `}
                      style={{
                        paddingTop:'15px',
                        paddingBottom:'15px',
                        fontWeight: "700",
                        width:'25%',
                        fontSize: "12px",
                        backgroundColor:
                          index % 2 === 0 ? "#FAF7FA" : "transparent",
                      }} // Apply background color conditionally
                    >
                         <CustomTooltip title={item.api_name}>
                          <div className={`${styles.makeellips}`}>
                      {item.api_name}</div></CustomTooltip>
                    </td>
                    <td
                      className={`${styles.valuetable}`}
                      style={{
                        paddingTop:'15px',
                        paddingBottom:'15px',
                        paddingLeft: "12px",
                        backgroundColor:
                          index % 2 === 0 ? "#FAF7FA" : "transparent",
                      }} // Apply background color conditionally
                    >
                      {item.credit_used}
                    </td>
                    <td
                      className={`${styles.valuetable}`}
                      style={{
                         paddingTop:'15px',
                        paddingBottom:'15px',
                        paddingLeft: "15px",
                        backgroundColor:
                          index % 2 === 0 ? "#FAF7FA" : "transparent",
                      }} // Apply background color conditionally
                    >
                      {item.available_credit}
                    </td>
                    <td
                      className={`${styles.valuetable}`}
                      style={{
                        paddingTop:'15px',
                        paddingBottom:'15px',
                        paddingLeft: "17px",
                        backgroundColor:
                          index % 2 === 0 ? "#FAF7FA" : "transparent",
                      }} // Apply background color conditionally
                    >
                      {item.addon_count === null ? "-" : item.addon_count}
                    </td>
                    <td
                      className={`${styles.valuetable}`}
                      style={{
                        paddingTop:'15px',
                        paddingBottom:'15px',
                        paddingLeft: "20px",
                        backgroundColor:
                          index % 2 === 0 ? "#FAF7FA" : "transparent",
                          width:'14%'
                      }} // Apply background color conditionally
                    >
                      {item.payment_date}
                    </td>
                    <td
                      className={`${styles.valuetable}`}
                      style={{
                        paddingTop:'15px',
                        paddingBottom:'15px',
                        paddingLeft: "23px",
                        backgroundColor:
                          index % 2 === 0 ? "#FAF7FA" : "transparent",
                          width:'11%'
                      }} // Apply background color conditionally
                    >
                      {item.expired_date}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>)}
      </div>
    </div>
  );
};

export default ApiPurchaseLog;
