import React from "react";

interface SvgIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const SvgBook: React.FC<SvgIconProps> = ({
  width = 17,
  height = 13,
  fill = "#581845",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9863 6.75358e-07H11.7529C10.8444 6.75358e-07 9.9563 0.260889 9.19214 0.753061L8.28921 1.3322L7.38628 0.753061C6.62288 0.260983 5.73376 -0.000484994 4.8255 6.75358e-07H0.592086C0.264589 6.75358e-07 0 0.264589 0 0.592087V11.1016C0 11.4291 0.264589 11.6937 0.592086 11.6937H4.8255C5.73399 11.6937 6.62212 11.9546 7.38628 12.4468L8.2078 12.9759C8.23185 12.9907 8.25961 13 8.28736 13C8.31511 13 8.34287 12.9926 8.36692 12.9759L9.18844 12.4468C9.95445 11.9546 10.8444 11.6937 11.7529 11.6937H15.9863C16.3138 11.6937 16.5784 11.4291 16.5784 11.1016V0.592087C16.5784 0.264589 16.3138 6.75358e-07 15.9863 6.75358e-07ZM4.8255 10.3615H1.33219V1.3322H4.8255C5.4805 1.3322 6.11699 1.51907 6.66652 1.87247L7.56946 2.45161L7.69712 2.53487V11.0831C6.8164 10.6095 5.83205 10.3615 4.8255 10.3615ZM15.2462 10.3615H11.7529C10.7464 10.3615 9.76202 10.6095 8.8813 11.0831V2.53487L9.00897 2.45161L9.9119 1.87247C10.4614 1.51907 11.0979 1.3322 11.7529 1.3322H15.2462V10.3615ZM6.15955 3.70054H2.72175C2.64959 3.70054 2.59038 3.76345 2.59038 3.83931V4.67193C2.59038 4.74779 2.64959 4.8107 2.72175 4.8107H6.1577C6.22986 4.8107 6.28907 4.74779 6.28907 4.67193V3.83931C6.29092 3.76345 6.23171 3.70054 6.15955 3.70054ZM10.2875 3.83931V4.67193C10.2875 4.74779 10.3467 4.8107 10.4189 4.8107H13.8548C13.927 4.8107 13.9862 4.74779 13.9862 4.67193V3.83931C13.9862 3.76345 13.927 3.70054 13.8548 3.70054H10.4189C10.3467 3.70054 10.2875 3.76345 10.2875 3.83931ZM6.15955 6.29092H2.72175C2.64959 6.29092 2.59038 6.35383 2.59038 6.42969V7.26231C2.59038 7.33817 2.64959 7.40108 2.72175 7.40108H6.1577C6.22986 7.40108 6.28907 7.33817 6.28907 7.26231V6.42969C6.29092 6.35383 6.23171 6.29092 6.15955 6.29092ZM13.8567 6.29092H10.4189C10.3467 6.29092 10.2875 6.35383 10.2875 6.42969V7.26231C10.2875 7.33817 10.3467 7.40108 10.4189 7.40108H13.8548C13.927 7.40108 13.9862 7.33817 13.9862 7.26231V6.42969C13.988 6.35383 13.9288 6.29092 13.8567 6.29092Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgBook;
