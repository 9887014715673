import React, { Suspense, useEffect, useState } from "react";
import { verifyToken } from "./configuration";
import Navbar from "./components/Navbar/Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Loader from "./uikit/Loader";

const Layout: React.FC = (): JSX.Element => {
  const [compHeight, setCompHeight] = useState(window.innerHeight - 67);
  const accessToken = sessionStorage.getItem("access_token");
  const idToken = sessionStorage.getItem("id_token");
  const [validationResult, setValidationResult] = useState<any | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleVerifyTokens = async () => {
      const accessToken = sessionStorage.getItem("access_token");
      const idToken = sessionStorage.getItem("id_token");

      if (accessToken && idToken) {
        try {
          const accessTokenPayload = await verifyToken(accessToken, "access");
          const idTokenPayload = await verifyToken(idToken, "id");
          setValidationResult({ accessTokenPayload, idTokenPayload });
        } catch (err) {
          console.error("Error verifying tokens:", err);
          setValidationResult(null);
          navigate("/");
        }
      } else {
        console.error("No access token or ID token found in session storage.");
        setValidationResult(null);
        navigate("/");
      }
    };
    handleVerifyTokens();
    if (!accessToken && !idToken) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCompHeight(window.innerHeight - 67);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log("validate", validationResult);

  return (
    <>
      {validationResult && (
        <div>
          <div
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 12,
              backgroundColor: "#FFFFFF",
              padding: "5px 0px 0px",
            }}
          >
            <Navbar />
          </div>

          <div
            style={{
              marginTop: "65px",
              position: "relative",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
            }}
          >
            <div style={{ width: "200px", marginTop: 15 }}>
              <Sidebar />
            </div>

            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                flex: "1 1 0%",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                borderRadius: "23px 0px  0px  0px",
                marginTop: "2px",
                height: compHeight,
                overflow: "auto",
              }}
            >
              <Suspense fallback={<Loader />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
