const SvgCopy: React.FC = (): JSX.Element => {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 3H2.5C1.67157 3 1 3.67157 1 4.5V14.5C1 15.3284 1.67157 16 2.5 16H8.5C9.32843 16 10 15.3284 10 14.5V4.5C10 3.67157 9.32843 3 8.5 3Z"
        stroke="#581845"
      />
      <path
        d="M3 2.5C3 2.10218 3.15804 1.72064 3.43934 1.43934C3.72064 1.15804 4.10218 1 4.5 1H10.5C10.8978 1 11.2794 1.15804 11.5607 1.43934C11.842 1.72064 12 2.10218 12 2.5V12.5C12 12.8978 11.842 13.2794 11.5607 13.5607C11.2794 13.842 10.8978 14 10.5 14"
        stroke="#581845"
      />
    </svg>
  );
};

export default SvgCopy;
