import React, { useEffect, useRef, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import yaml from "yaml";

const Comparative = `

openapi: 3.0.1
info:
  title: Comparative Analysis API
  description: API for performing comparative analysis between job descriptions and resumes
  version: 1.0.0

servers:
  - url: 'https://uotxnvwte6.execute-api.us-east-1.amazonaws.com/prod'
    description: API Server

tags:
  - name: Comparative Analysis
    description: Operations related to comparative analysis

components:
  securitySchemes:
    ApiKeyAuth:
      type: apiKey
      in: header
      name: x-api-key
  responses:
    ErrorResponse:
      description: An error occurred
      content:
        application/json:
          schema:
            type: object
            properties:
              error:
                type: string
                example: An error occurred

paths:
  /comparitive_analysis:
    post:
      tags:
        - Comparative Analysis
      summary: Perform Comparative Analysis
      description: |
        Perform a comparative analysis between job descriptions and resumes.

        **Sample Input:**

        
        - jd: (binary),
        - resume1: (binary),
        - resume2: (binary),
        - resume3: (binary),
        - Roles and Responsibilities: True,
        - Industry-Specific Experience: True,
        - Domain Specific Experience: True,
        - Skills: True
        - Experience: True
        - Certification: True
        - Technical Tools and Languages: True
        - Location Alignment: True
        - Soft Skills: True
        - Cultural Fit: True
        - Qualification: True
        - References and Recommendation:True
      operationId: performComparativeAnalysis
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                jd:
                  type: string
                  format: binary
                  description: Job description file for comparative analysis
                resume1:
                  type: string
                  format: binary
                  description: First resume for comparative analysis
                resume2:
                  type: string
                  format: binary
                  description: Second resume for comparative analysis
                resume3:
                  type: string
                  format: binary
                  description: Third resume for comparative analysis
                Roles and Responsibilities:
                  type: string
                  description: Include roles and responsibilities in the analysis
                  default: ''
                Industry-Specific Experience:
                  type: string
                  description: Include industry-specific experience in the analysis
                  default: ''
                Domain Specific Experience:
                  type: string
                  description: Include domain-specific experience in the analysis
                  default: ''
                Skills:
                  type: string
                  description: Include skills in the analysis
                  default: ''
                Experience:
                  type: string
                  description: Include experience in the analysis
                  default: ''
                Certification:
                  type: string
                  description: Include certification in the analysis
                  default: ''
                Technical Tools and Languages:
                  type: string
                  description: Include technical tools and language in the analysis
                  default: ''
                Location Alignment:
                  type: string
                  description: Include location alignment in the analysis
                  default: ''
                Soft Skills:
                  type: string
                  description: Include soft skills in the analysis
                  default: ''
                Cultural Fit:
                  type: string
                  description: Include cultural fit in the analysis
                  default: ''
                Qualification:
                  type: string
                  description: Include qualification in the analysis
                  default: ''
                References and Recommendation:
                  type: string
                  description: Include reference and recommendation in the analysis
                  default: ''
      responses:
        '200':
          description: Comparative analysis performed successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true
        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters
        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.
        '403':
          description: Forbidden
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.
        '404':
          description: User Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.
        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  error:
                    type: string
                    example: Internal server error


`;
const spec = yaml.parse(Comparative);

const ComparartiveDoc = () => {
  const [apiKey, setApiKey] = useState(
    "lFvgBTlFhKt2cxE8EF7u88KNp9Q92Nm7kCX8Pfh7"
  ); // State for API key
  const swaggerUIRef = useRef(null); // Ref for SwaggerUI instance

  // useEffect(() => {
  //   if (swaggerUIRef.current) {
  //     const ui = swaggerUIRef.current;
  //     // Set the API key for the SwaggerUI instance
  //     ui.getConfigs().authActions.authorize({
  //       ApiKeyAuth: { apiKey },
  //     });
  //   }
  // }, [apiKey]);

  return (
    <div>
      <style>
        {`
          .swagger-container {
            margin: 20px auto;
            max-width: 90%;
            box-sizing: border-box;
          }

          .swagger-ui .info {
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }

          .swagger-ui .opblock-summary-description {
            white-space: normal; /* Prevents text from overflowing */
          }

          /* Custom horizontal layout for method call header */
          .swagger-ui .opblock-summary {
            display: flex; /* Use flexbox for horizontal layout */
            align-items: center; /* Align items vertically in the center */
            justify-content: flex-start; /* Align items to the start horizontally */
            white-space: nowrap; /* Prevent text from wrapping */
            overflow: hidden; /* Hide overflow */
            max-width: 100%; /* Ensure it uses the full width of the container */
          }

          .swagger-ui .opblock-summary-method {
            margin-right: 12px; /* Space between method and path */
            font-weight: bold; /* Make the method text bold */
            white-space: nowrap; /* Prevent wrapping for method */
          }

          .swagger-ui .opblock-summary-path {
            margin-right: 12px; /* Space between path and description */
            white-space: nowrap; /* Prevent wrapping for path */
            text-overflow: ellipsis; /* Add ellipsis if the path is too long */
            overflow: hidden; /* Hide any overflowing text */
            max-width: 300px; /* Set a maximum width for the path */
            min-width: 150px; /* Set a minimum width for the path to avoid collapse */
          }

          .swagger-ui .opblock-summary-description {
            flex: 1; /* Allow the description to take the remaining space */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the description is too long */
            max-width: 400px; /* Set a maximum width for the description */
            min-width: 200px; /* Set a minimum width for the description to avoid collapse */
          }

          .swagger-ui .opblock-summary-controls {
            margin-left: auto; /* Push the controls to the far right */
            display: flex; /* Use flexbox for any control buttons */
            align-items: center; /* Align controls vertically in the center */
            white-space: nowrap; /* Prevent controls from wrapping */
          }

          /* SVG icons */
          .swagger-ui svg {
            flex-shrink: 0; /* Prevent icons from shrinking */
          }

          /* Static header setup for response table */
          .swagger-ui .response-col_description {
            width: 300px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          .swagger-ui .response-col_status {
            width: 80px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          .swagger-ui .response-col_links {
            width: 100px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          /* Fix table headers to ensure consistent layout */
          .swagger-ui .table th {
            white-space: nowrap; /* Prevent wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis for overflow */
          }

          .swagger-ui .table td {
            white-space: nowrap; /* Prevent wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis for overflow */
          }

          /* Ensure the table itself has a fixed layout */
          .swagger-ui .table {
            table-layout: fixed; /* Ensure fixed layout */
            width: 100%; /* Use full width */
          }

          /* Override the problematic rule for parameters-col_description */
          .swagger-ui .parameters-col_description {
            margin-bottom: 0; /* Remove the margin if it causes issues */
            width: auto; /* Set width to auto to allow proper resizing */
            white-space: normal; /* Allow text wrapping */
            overflow-wrap: break-word; /* Break long words */
          }
          
          .swagger-ui .opblock-summary-description {
            white-space: normal; /* Prevents text from overflowing */
          }
          /* Hide "No links" text */
          .swagger-ui .response-col_links i {
            display: none;
          }

          /* Optionally hide the entire "Links" column if needed */
          .swagger-ui .response-col_links {
            display: none;
          }

        `}
      </style>
      <SwaggerUI
        spec={spec}
        onComplete={(swaggerUI: {
          getConfigs: () => {
            (): any;
            new (): any;
            authActions: {
              (): any;
              new (): any;
              authorize: {
                (arg0: { ApiKeyAuth: { apiKey: string } }): void;
                new (): any;
              };
            };
          };
        }) => {
          // If SwaggerUI is available, set the API key for authorization
          if (swaggerUI && swaggerUI.getConfigs) {
            swaggerUI.getConfigs().authActions.authorize({
              ApiKeyAuth: { apiKey },
            });
          }
        }}
      />
    </div>
  );
};

export default ComparartiveDoc;
