import * as React from 'react';
const SvgAnalytics = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    fill="none"
    stroke="#1E1E1E"
    viewBox="0 0 15 15"
    {...props}
  >
    <path
      stroke={props.stroke}
      d="M5.333 14h4.334m-4.334 0v-3.611m0 3.611h-3.9A.434.434 0 0 1 1 13.567v-2.745a.433.433 0 0 1 .433-.433h3.9M9.667 14V5.333m0 8.667h3.9a.434.434 0 0 0 .433-.433V1.433A.434.434 0 0 0 13.567 1H10.1a.433.433 0 0 0-.433.433v3.9M5.333 10.39V5.767a.433.433 0 0 1 .434-.434h3.9"
    />
  </svg>
);
export default SvgAnalytics;