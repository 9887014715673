import * as React from 'react';
const SvgBilling = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="#1E1E1E"
    viewBox="0 0 12 13"
    {...props}
  >
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M2.932 0H8.86c.614 0 1.038 0 1.394.123a2.3 2.3 0 0 1 1.42 1.459c.118.36.117.79.117 1.427v8.554c0 .886-1.044 1.414-1.731.784a.12.12 0 0 0-.164 0l-.293.268a1.454 1.454 0 0 1-1.972 0 .55.55 0 0 0-.749 0 1.454 1.454 0 0 1-1.972 0 .55.55 0 0 0-.748 0 1.454 1.454 0 0 1-1.973 0l-.292-.268a.12.12 0 0 0-.164 0c-.688.63-1.732.103-1.732-.784V3.01c0-.637 0-1.066.118-1.427A2.3 2.3 0 0 1 1.538.123C1.894 0 2.318 0 2.932 0m.088.907c-.738 0-.993.006-1.185.073a1.39 1.39 0 0 0-.855.885c-.067.204-.072.474-.072 1.23v8.468c0 .073.035.116.08.137a.12.12 0 0 0 .067.011.12.12 0 0 0 .065-.033 1.025 1.025 0 0 1 1.388 0l.292.267a.55.55 0 0 0 .749 0 1.455 1.455 0 0 1 1.972 0 .55.55 0 0 0 .748 0 1.455 1.455 0 0 1 1.973 0 .55.55 0 0 0 .748 0l.292-.267a1.025 1.025 0 0 1 1.389 0 .11.11 0 0 0 .13.022c.046-.02.082-.065.082-.137V3.096c0-.757-.006-1.027-.072-1.232A1.39 1.39 0 0 0 9.955.98C9.763.914 9.509.908 8.77.908zm4.69 2.836a.454.454 0 0 1 .036.64l-2.16 2.42a.453.453 0 0 1-.676 0l-.864-.968a.453.453 0 0 1 .676-.605l.526.589 1.821-2.04a.453.453 0 0 1 .64-.036M2.72 8.616a.454.454 0 0 1 .454-.453h5.442a.453.453 0 0 1 0 .907H3.175a.454.454 0 0 1-.454-.454"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBilling;