export default function SvgApiProductsCheck(): JSX.Element {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8 0C4.8357 0 0 4.8357 0 10.8C0 16.7644 4.8357 21.6 10.8 21.6C16.7644 21.6 21.6 16.7644 21.6 10.8C21.6 4.8357 16.7644 0 10.8 0ZM9.0369 15.3L4.9869 11.4129L6.2424 10.1214L9.0144 12.7646L15.3334 6.3L16.613 7.5681L9.0369 15.3Z"
        fill="#34CC65"
      />
    </svg>
  );
}
