import React, { useEffect } from "react";
import { useState } from "react";
import AnalyticTable from "./AnalyticsTable";
import AnalyticsTitle from "./AnalyticsTitle";
import ApiUseage from "./ApiUseage";
import Loader from "../../uikit/Loader";
import OverViewDetails from "./OverViewDetails";
import Styles from "./analyticstable.module.css";
import ErrorRate from "./ErrorRate";
import ApiPurchaseLog from "./ApiPurchaseLog";
import { Analyticsdata } from "../../api";
import SvgNoReport from "../../icons/SvgNoReport";
import moment from "moment";
import { notify } from "../../uikit/toast";
import { differenceInDays, parse } from "date-fns";

interface ApiUsageData {
  lable: string[];
  day: number[];
  api_cals: number[];
  error_rate: number[];
  credits_log: number[];
}
interface Analytics {
  access_count: number;
  api_name: string;
  credits_consumed: number;
  error_rate: number;
  expired_at: string; // Consider using Date if you are working with date objects
  name: string;
  no_of_request: number;
  purchased_at: string; // Consider using Date if you are working with date objects
  remaining_credits: number;
  is_download: boolean;
}

interface PurchaseLogEntry {
  api_name: string;
  credits: string;
  description: string;
  download_url: string;
  email: string;
  expired_date: string; // Consider using Date if appropriate
  invoice: string;
  name: string;
  next_billing_date: string; // Consider using Date if appropriate
  paid: boolean;
  payment_date: string; // Consider using Date if appropriate
  total: string;
  type: string;
}

interface ErrorRateData {
  date: string;
  statuscode: number;
  Count: number;
}

interface RequestState {
  no_of_request: number;
  credits_consumed: number;
  remaining_credits: number;
}

function AnalyticScreen() {
  const [value, setvalue] = useState<number>(1);
  const [chartData, setChartData] = useState<ApiUsageData>();
  const [apiDatauseage, setApiDatauseage] = useState<PurchaseLogEntry[]>([]);
  const [errorRateData, setErrorRateData] = useState<ErrorRateData[]>([]);
  const [data, setdata] = useState<Analytics[]>([]);
  const [isDatepicker, setDatepicker] = useState<any>();
  const [ovralldays, setoverallDate] = useState<any>();
  const [api_name, setapi_name] = useState<string>("");
  const [name, setname] = useState<string>("");
  const [loader, setloader] = useState(false);
  const [chartload, setchartload] = useState(false);
  const [request, setrequest] = useState<RequestState>({
    no_of_request: 0,
    credits_consumed: 0,
    remaining_credits: 0,
  });

  const [isCurrentWeek, setisCurrentWeek] = useState<boolean>(true);
  const [isButtonloader, setButtonloader] = useState<boolean>(false);
  const [isScroll, setScroll] = useState(false);
  const handleShow = () => {
    setScroll(true);
  };

  const handleHide = () => {
    setScroll(false);
  };

  const updateValue = (key: string, newValue: number) => {
    setrequest((prevState) => ({
      ...prevState, // Spread the previous state to retain other properties
      [key]: newValue, // Update the specific key with the new value
    }));
  };
  const [fromdate,setFromDate]=useState<any>()
  const [todate,setToDate]=useState<any>()


  const today = moment();
  const initialStartDate = today.clone().subtract(6, "days");

  useEffect(() => {
    setDatepicker({
      startDate: initialStartDate.format("YYYY-MM-DD"),
      endDate: today.format("YYYY-MM-DD"),
    });
  }, []);

  useEffect(() => {
    if (!isDatepicker?.startDate || !isDatepicker?.endDate) {
      return;
    }
    if (isDatepicker?.startDate && isDatepicker?.endDate) {
      const daysBetween = calculateDaysBetweenDates(
        isDatepicker?.startDate,
        isDatepicker?.endDate
      );
      setoverallDate(`${daysBetween + 1}`);
    }

    if (api_name === "") {
      setloader(true);
      Analyticsdata()
        .then((res) => {
          console.log("main", res.data.response);
          setdata(res.data.response);
          if (res.data.response.length === 0) {
            setvalue(0);
          }
          setloader(false);
        })
        .catch((error) => {
          if (error.response) {
            console.log("dataaaa");
            setloader(false);
          }
        });
    } else {
      getparticularApi()
      // setchartload(false);
      if (chartload === false) {
        setloader(true);
        // setchartload(true);
     
      }
    }
  }, [api_name, isDatepicker]);

  const calculateDaysBetweenDates = (
    startDate: string,
    endDate: string
  ): number => {
    // Define the date format used in the input strings
    const dateFormat = "yyyy-MM-dd";

    // Parse the dates from the input strings
    const start = parse(startDate, dateFormat, new Date());
    const end = parse(endDate, dateFormat, new Date());

    // Calculate the difference in days
    return differenceInDays(end, start);
  };

  const getparticularApi = () => {
    var param = {
      api_name: name,
      from_date: isDatepicker?.startDate,
      to_date: isDatepicker?.endDate,
      csv_download: false,
    };

    Analyticsdata(param)
      .then((res) => {
        setloader(false);
        setchartload(false);
        updateValue("no_of_request", res.data.total_requests);
        updateValue("credits_consumed", res.data.total_credits);
        updateValue("remaining_credits", res.data.remaining_credits);
        setErrorRateData(res.data.error_rate);
        setApiDatauseage(res.data.purchase_log);
        setChartData(res.data.weekly_api_usage);
        setFromDate(res.data.from_purchased_date);
        setToDate(res.data.to_purchased_date);
        console.log("ressss", res.data.purchase_log);
        // setdata(res.data.response);
      })
      .catch((error) => {
        setloader(false);
        if (error.response) {
        }
      });
  };

  {console.log("sssssspp",moment(fromdate).format("DD-MM-YYYY") 
  )}
  const backanalytics = () => {
    setapi_name("");
    setchartload(false);
    setvalue(1);
    setDatepicker({
      startDate: initialStartDate.format("YYYY-MM-DD"),
      endDate: today.format("YYYY-MM-DD"),
    });
  };

  const download = () => {
    var param = {
      api_name: name,
      from_date: isDatepicker?.startDate,
      to_date: isDatepicker?.endDate,
      csv_download: true,
    };

    Analyticsdata(param)
      .then((res) => {
        setloader(false);

        const downloadUrl = res.data.download_url;
        // Create an anchor element to trigger the download
        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = ""; // Optionally specify a filename here
        document.body.appendChild(anchor);
        anchor.click();
        // Remove the anchor element after triggering the download
        document.body.removeChild(anchor);
        notify("Report downloaded successfully!", "success", 3000);
        setButtonloader(false);
      })
      .catch((error) => {
        setloader(false);
        setButtonloader(false);
      });
  };

  const Tabledownload = (api_name: string,start:any,end:any) => {
    var param = {
      api_name: api_name,
      from_date: moment(start).format("YYYY-MM-DD") ,
      to_date: moment(end).format("YYYY-MM-DD") ,
      csv_download: true,
    };

    Analyticsdata(param)
      .then((res) => {
        setloader(false);

        const downloadUrl = res.data.download_url;
        // Create an anchor element to trigger the download
        const anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = ""; // Optionally specify a filename here
        document.body.appendChild(anchor);
        anchor.click();
        // Remove the anchor element after triggering the download
        document.body.removeChild(anchor);
        notify("Report downloaded successfully!", "success", 3000);
        setButtonloader(false);
      })
      .catch((error) => {
        setloader(false);
        setButtonloader(false);
      });
  };

  console.log("aa", isDatepicker);

  const datefunction = (from: any, end: any) => {
    var param = {
      api_name: name,
      from_date: from,
      to_date: end,
      csv_download: false,
    };

    Analyticsdata(param)
      .then((res) => {
        // setloader(false);
        setchartload(false);
        updateValue("no_of_request", res.data.total_requests);
        updateValue("credits_consumed", res.data.total_credits);
        updateValue("remaining_credits", res.data.remaining_credits);
        setErrorRateData(res.data.error_rate);
        setApiDatauseage(res.data.purchase_log);
        setChartData(res.data.weekly_api_usage);
        console.log("ressss", res.data.purchase_log);
        // setdata(res.data.response);
      })
      .catch((error) => {
        setloader(false);
        if (error.response) {
        }
      });
  };
{console.log('sssss',isDatepicker)}
  return (
    <>
      {loader === true ? (
        <>
          <Loader />
        </>
      ) : (
        <div
          className="container"
          style={{ overflow: isScroll ? "hidden" : "" }}
        >
          <div className="row">
            <div
              style={{
                position: "sticky",
                top: "0",
                zIndex: "3",
                background: "white",
                paddingBottom: "10px",
              }}
            >
              <AnalyticsTitle />
            </div>

            {value === 0 ? (
              <div
                className="d-flex justify-content-center align-items-center flex-column"
                style={{
                  // minHeight: "100vh",
                  width: "100%",
                  position: "absolute",
                  top: "50%",
                  overflow: "hidden",
                }}
              >
                <div className={Styles.icon}>
                  <SvgNoReport />
                </div>
                <div className={Styles.noreport}>
                  Looks like there are no report details available.
                </div>
              </div>
            ) : (
              ""
            )}

            {value === 1 ? (
              <>
                <div style={{ marginTop: "10px" }}>
                  <AnalyticTable
                    valuechange={setvalue}
                    data={data}
                    updateValue={updateValue}
                    setapi_name={setapi_name}
                    setname={setname}
                    Tabledownload={Tabledownload}
                  />
                </div>
              </>
            ) : (
              ""
            )}

            {value === 2 ? (
              <div>
                <div >
                  <span
                    onClick={() => backanalytics()}
                    style={{
                      marginRight: "2px",
                      fontSize: "14px",
                      fontWeight: 700,
                      cursor: "pointer",
                      color: "#581845",
                    }}
                  >
                    {" "}
                    Analytics{" "}
                  </span>
                  <span style={{ fontSize: "14px", fontWeight: 400 }}>
                    {" "}
                    / {api_name}{" "}
                  </span>
                </div>
                <OverViewDetails data={request} fromdate={fromdate} todate={todate}/>
                {chartData && (
                  <ApiUseage
                    data={chartData}
                    setDatepicker={setDatepicker}
                    ovralldays={ovralldays}
                    download={download}
                    setLoading={setchartload}
                    setisCurrentWeek={setisCurrentWeek}
                    isCurrentWeek={isCurrentWeek}
                    isDatepicker={isDatepicker}
                    setButtonloader={setButtonloader}
                    isButtonloader={isButtonloader}
                    handleShow={handleShow}
                    handleHide={handleHide}
                    chartload={chartload}
                    datefunction={datefunction}
                  />
                )}

                <div className="row my-3">
                  <div className="col-lg-7 col-md-7 col-sm-12 mb-3 pe-md-0 pe-lg-0">
                    <ApiPurchaseLog data={apiDatauseage} />
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <ErrorRate
                      data={errorRateData}
                      ovralldays={ovralldays}
                      isCurrentWeek={isCurrentWeek}
                      isDatepicker={isDatepicker}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AnalyticScreen;
