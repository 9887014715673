import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { applicationapi } from "../../api";

const idToken = sessionStorage.getItem("id_token");


interface AuthenticationData {
  org_id: string;
  access_url: string;
  response: string;
}

interface AuthenticationState {
  name: string | null;
  isLoading: boolean;
  isError: boolean;
  data: AuthenticationData | null;  // need to change
}

interface AuthenticationFetchParams {
  generate: boolean;
}



export const fetchAuthenticationData= createAsyncThunk<AuthenticationData>(
  "authentication/fetchAuthenticationData",
  async (_, thunkAPI) => {
    const url="/apikey"
    const idToken = sessionStorage.getItem("id_token");
    try {
      const response = await applicationapi.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );
     // console.log(response.data)
      return response.data.data;
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({
              message: "Incorrect Tocken. Please try again.",
              status: 401,
            });
          case 404:
            return thunkAPI.rejectWithValue({
              message: "Resource not found.",
              status: 404,
            });
          case 500:
            return thunkAPI.rejectWithValue({
              message: "Internal server error. Please try again later.",
              status: 500,
            });
          default:
            return thunkAPI.rejectWithValue({
              message:
                error.response?.data?.data?.message || "Failed to fetch data",
              status: error.response.status,
            });
        }
      } else {
        return thunkAPI.rejectWithValue({
          message: "Network error or server did not respond.",
          status: null,
        });
      }
    }
  }
);

export const resetSecretKey = createAsyncThunk<AuthenticationData,AuthenticationFetchParams>(
  "authentication/resetSecretKey",
  async (params, thunkAPI) => {
    const url = "/apikey";
    const idToken = sessionStorage.getItem("id_token");
    try {
      const response = await applicationapi.get(
        url,
        {
          params: {
            generate: params.generate ? 'True' : 'False',  
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );
     // console.log("Reset response",response)
      return response.data.data;
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({
              message: "Incorrect Token. Please try again.",
              status: 401,
            });
          case 404:
            return thunkAPI.rejectWithValue({
              message: "Resource not found.",
              status: 404,
            });
          case 500:
            return thunkAPI.rejectWithValue({
              message: "Internal server error. Please try again later.",
              status: 500,
            });
          default:
            return thunkAPI.rejectWithValue({
              message:
                error.response?.data?.data?.message || "Failed to reset key",
              status: error.response.status,
            });
        }
      } else {
        // The request was made but no response was received
        return thunkAPI.rejectWithValue({
          message: "Network error or server did not respond.",
          status: null,
        });
      }
    }
  }
);



const initialState: AuthenticationState = {
  name: null,
  isLoading: false,
  isError: false,
  data: null,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthenticationData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchAuthenticationData.fulfilled,
        (state, action: PayloadAction<AuthenticationData>) => {
          state.isLoading = false;
          state.data = action.payload; 
        }
      )
      .addCase(fetchAuthenticationData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(resetSecretKey.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(
        resetSecretKey.fulfilled,
        (state, action: PayloadAction<AuthenticationData>) => {
          state.isLoading = false;
          state.data = action.payload;  
        }
      )
      .addCase(resetSecretKey.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default authenticationSlice.reducer;
