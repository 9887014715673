import { useDispatch, useSelector } from "react-redux";
import SvgBilling from "../../icons/SvgBilling";
import BillingModule from "./BillingModule";
import { ModuleName } from "./BillingTypes";
import { AppDispatch, RootState } from "../../redux/store/store";
import { useEffect } from "react";
import { billingapi } from "../../redux/reducers/BillingSlice";
import Loader from "../../uikit/Loader";

export default function Billing() {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, items, length} = useSelector((state: RootState) => state.Billing);

  useEffect(() => {
    if (length >= 0) {
      if (isLoading === false && items.length === 0){
        dispatch(billingapi({}))
      }
    } 
    
  }, [dispatch, items])

  //console.log("isLoading",isLoading,length,items)
  if (isLoading === true) return <Loader />;
  return (
    <>
      {isLoading === false ? (
          <div className="container p-3 mt-2">
            <div className="d-flex mb-3 align-items-center">
              <SvgBilling height={18} width={20} />
              <span
                className="d-flex align-items-center ms-1"
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                {ModuleName}
              </span>
            </div>
            <BillingModule />
          </div>
        ):('')}
    </>
  );
};
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}

