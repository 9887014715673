import { CognitoJwtVerifier } from "aws-jwt-verify";

const userPoolId = "us-east-1_546RUb4gO";
const region = "us-east-1";

const idTokenVerifier = CognitoJwtVerifier.create({
  userPoolId,
  clientId: "ovkvo2lg5g4j89rash1nmm5q5",
  tokenUse: "id",
  region,
});

const accessTokenVerifier = CognitoJwtVerifier.create({
  userPoolId,
  clientId: "ovkvo2lg5g4j89rash1nmm5q5",
  tokenUse: "access",
  region,
});

export async function verifyToken(token: string, tokenUse: "id" | "access") {
  try {
    let payload;

    if (tokenUse === "id") {
      payload = await idTokenVerifier.verify(token);
    } else if (tokenUse === "access") {
      payload = await accessTokenVerifier.verify(token);
    } else {
      throw new Error("Invalid token use type");
    }

    // console.log(
    //   `${
    //     tokenUse.charAt(0).toUpperCase() + tokenUse.slice(1)
    //   } Token is valid. Payload:`,
    //   payload
    // );

    return payload;
  } catch (err) {
    // console.error(
    //   `${
    //     tokenUse.charAt(0).toUpperCase() + tokenUse.slice(1)
    //   } Token is invalid:`,
    //   err
    // );
    throw err;
  }
}
