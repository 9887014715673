import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import { notify } from "../../uikit/toast";
import styles from './rangedatepicker.module.css';

interface DatePickerProps {
  setDatepicker?: (value: any) => void;
  isDatepicker?: any;
  setinnerloader?: (value: any) => void;
  setisCurrentWeek?: (value: any) => void;
}

export default function RangeDatePicker({ setDatepicker, isDatepicker, setinnerloader, setisCurrentWeek }: DatePickerProps) {
  const datePickerRef = useRef<any>(null); // Reference to the DatePicker component
  const wrapperRef = useRef<HTMLDivElement>(null); // Reference to the wrapper containing DatePicker
  
  const todayfinal = new DateObject();
  const today = new DateObject({ date: isDatepicker?.endDate, format: "YYYY-MM-DD" });
  const last7DaysStartDate = new DateObject({ date: isDatepicker?.startDate, format: "YYYY-MM-DD" });

  const [selectedDates, setSelectedDates] = useState([last7DaysStartDate, today]);
  const [lastValidDates, setLastValidDates] = useState([last7DaysStartDate, today]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  // Helper function to ensure a minimum of 7 days is selected
  const handleDateChange = (dates: any) => {
    if (dates.length === 2) {
      const [startDate, endDate] = dates;
      const start = new Date(startDate);
      const end = new Date(endDate);
      const today = new Date(); 
      const diffTime = Math.abs(end.getTime() - start.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      if (diffDays >= 6) {
        setSelectedDates(dates);
        setLastValidDates(dates);
        setDatepicker?.({
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        });
        setinnerloader?.(true);
        setIsCalendarOpen(false); // Close calendar after a valid selection
        datePickerRef.current.closeCalendar();
        if (start >= sevenDaysAgo && end <= today) {
          setisCurrentWeek?.(true); 
        } else {
          setisCurrentWeek?.(false); 
        }
      } else {
        notify("Please select a date range of at least 7 days.", "error", 3000);
        setSelectedDates(lastValidDates); 
      }
    } else {
      setSelectedDates(dates);
    }
  };

  const toggleCalendar = () => {
    if (isCalendarOpen) {
      datePickerRef.current.closeCalendar();
    } else {
      datePickerRef.current.openCalendar();
    }
    setIsCalendarOpen(!isCalendarOpen); 
  };

  const formatDateRange = (dates: any) => {
    if (dates.length === 2) {
      const [startDate, endDate] = dates;
      return `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;
    } else if (dates.length < 2) {
      const [startDate, endDate] = lastValidDates;
      return `${startDate.format("DD/MM/YYYY")} - ${endDate.format("DD/MM/YYYY")}`;
    }
    return "";
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsCalendarOpen(false); // Close the calendar when clicked outside
      datePickerRef.current.closeCalendar(); // Close the DatePicker component
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef}>
      <DatePicker
        zIndex={0}
        ref={datePickerRef}
        value={selectedDates}
        onChange={handleDateChange}
        style={{ zIndex: 0 }}
        range
        numberOfMonths={2}
        minDate={undefined}
        maxDate={todayfinal}
        format="DD/MM/YYYY"
        calendarPosition="bottom-right"
        render={(value, openCalendar) => (
          <div style={{ position: "relative" }}>
            <input
              readOnly
              value={formatDateRange(selectedDates)}
              onClick={toggleCalendar} 
              className={styles.customdateinput}
            />
            <div
              style={{
                position: "absolute",
                top: 1,
                right: 5,
                cursor: "pointer",
              }}
              onClick={toggleCalendar} 
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="#581845"
                stroke="#581845"
                strokeWidth={38.4}
                viewBox="-192 -192 2304 2304"
              >
                <path
                  fillRule="evenodd"
                  d="M1411.824 0c31.17 0 56.47 25.3 56.47 56.471v56.47h169.412c93.403 
                  0 169.412 76.01 169.412 169.412V1920H113V282.353c0-93.402 76.009-169.412 
                  169.412-169.412h169.41v-56.47c0-31.172 25.3-56.47 56.472-56.47s56.47 
                  25.298 56.47 56.47v56.47h790.589v-56.47c0-31.172 25.299-56.47 
                  56.47-56.47Zm282.352 564.705H225.942v1242.353h1468.234V564.705ZM451.823 225.882H282.412c-31.06 
                  0-56.47 25.3-56.47 56.471v169.412h1468.234V282.353c0-31.172-25.411-56.47-56.47-56.47h-169.412v56.47c0 31.172-25.3 56.471-56.47 
                  56.471-31.172 0-56.471-25.299-56.471-56.47v-56.472H564.765v56.471c0 31.172-25.3 56.471-56.471 
                  56.471-31.171 0-56.471-25.299-56.471-56.47v-56.472Zm507.682 1190.74c-82.334 
                  0-141.515-34.448-194.372-95.888l-90.804 83.351c62.23 74.992 154.277 128.075 283.143 128.075 154.278 
                  0 258.862-96.79 258.862-231.19v-2.034c0-134.4-105.6-190.644-217.525-204.198l205.892-208.263v-95.887H715.552V902.06h320.076l-199.68 
                  209.394 21.12 80.188h60.31c103.454 0 170.09 40.546 170.09 112.376v2.147c0 66.635-55.003 
                  110.456-127.963 110.456"
                />
              </svg>
            </div>
          </div>
        )}
      />
    </div>
  );
}
