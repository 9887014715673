import * as React from "react";
const SvgAccSettings = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="#1E1E1E"
    viewBox="0 0 12 13"
    {...props}
  >
    <path
      fill={props.fill}
      d="m11.32 8.152-.887-.812a5.6 5.6 0 0 0 0-1.678l.886-.812a.47.47 0 0 0 .137-.234.5.5 0 0 0-.011-.277l-.012-.038a6.6 6.6 0 0 0-1.078-2l-.024-.03a.43.43 0 0 0-.218-.149.4.4 0 0 0-.257.01l-1.1.42a4.7 4.7 0 0 0-1.348-.836L7.196.482a.5.5 0 0 0-.12-.244.42.42 0 0 0-.23-.128L6.81.102a5.7 5.7 0 0 0-2.15 0L4.624.11a.43.43 0 0 0-.229.128.5.5 0 0 0-.12.244l-.214 1.24a4.7 4.7 0 0 0-1.339.832l-1.107-.422a.4.4 0 0 0-.258-.01.43.43 0 0 0-.217.148l-.024.03A6.6 6.6 0 0 0 .038 4.302l-.012.038a.49.49 0 0 0 .125.51l.897.822a5.5 5.5 0 0 0 0 1.657l-.897.822a.47.47 0 0 0-.137.234.5.5 0 0 0 .012.276l.012.038a6.6 6.6 0 0 0 1.078 2l.024.031a.43.43 0 0 0 .217.148.4.4 0 0 0 .258-.01l1.107-.422c.403.355.854.637 1.34.833l.213 1.239a.5.5 0 0 0 .12.245.42.42 0 0 0 .229.128l.036.007c.711.137 1.44.137 2.15 0l.037-.007a.42.42 0 0 0 .229-.128.5.5 0 0 0 .12-.245l.212-1.233c.49-.197.945-.48 1.348-.836l1.1.42a.4.4 0 0 0 .257.01.43.43 0 0 0 .218-.149l.024-.03a6.6 6.6 0 0 0 1.078-2.001l.012-.038a.49.49 0 0 0-.126-.508M9.472 5.834a4.4 4.4 0 0 1 0 1.338l-.09.581 1.011.928a5.5 5.5 0 0 1-.576 1.068L8.563 9.27l-.425.374a3.7 3.7 0 0 1-1.072.665l-.515.207-.242 1.408a4.8 4.8 0 0 1-1.15 0l-.242-1.41-.511-.21a3.7 3.7 0 0 1-1.064-.664l-.425-.376-1.263.482a5.6 5.6 0 0 1-.576-1.068l1.02-.936-.087-.58a4.5 4.5 0 0 1 0-1.32l.088-.581-1.021-.936q.23-.57.576-1.068l1.263.482.425-.376c.32-.283.679-.506 1.064-.663l.512-.207.243-1.41a4.8 4.8 0 0 1 1.149 0l.242 1.407.515.207c.388.157.75.38 1.073.665l.424.374L9.82 3.26q.345.5.576 1.068l-1.01.927zm-3.736-2.03c-1.315 0-2.38 1.143-2.38 2.554S4.422 8.91 5.737 8.91s2.38-1.143 2.38-2.553-1.066-2.554-2.38-2.554m1.07 3.703c-.14.15-.307.27-.49.352a1.4 1.4 0 0 1-.58.124 1.46 1.46 0 0 1-1.071-.476c-.141-.151-.253-.33-.33-.527a1.7 1.7 0 0 1-.114-.622c0-.434.158-.842.444-1.15a1.45 1.45 0 0 1 1.07-.476c.405 0 .785.169 1.072.476.14.151.252.33.328.527.077.198.116.41.115.623 0 .433-.158.841-.443 1.149"
    />
  </svg>
);
export default SvgAccSettings;
