import React, { useState, useEffect } from "react";
import styles from "../Login/login.module.css";
import SvgZita from "../../icons/SvgZita";
import { useNavigate } from "react-router-dom";
import { ResendCode, VerifySignupapi } from "../../api";
import { notify } from "../../uikit/toast";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";

const Codeverification = () => {
  const version = process.env.REACT_APP_VERSION;
  const navigate = useNavigate();
  const [code, setcode] = useState<string>("");
  const [codeerrortext, setcodeerrortext] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [submit, setsubmit] = useState<boolean>(false);
  const [loader, setloader] = useState<boolean>(false);
  const verifyemail = sessionStorage.getItem("ver-email") || "";

  useEffect(() => {
    const verifyemail = sessionStorage.getItem("ver-email");
    if (verifyemail === undefined || verifyemail === null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsExpired(true);
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const resendotp = () => {
    setTimeLeft(60);
    setIsExpired(false);
    ResendCode(verifyemail)
      .then((res) => {
        notify("Verification code resent successfully.", "success", 3000);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const codehandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setcodeerrortext("");
    const value = e.target.value.trim();
    setcode(value);
    if (value.length !== 6 && value.length !== 0) {
      setcodeerrortext("Verification code is invalid.");
    } else if (value.length === 0) {
      setcodeerrortext("Please enter verification code.");
    } else {
      setcodeerrortext("");
    }
  };

  const handlesubmit = () => {
    setsubmit(true);
    if (code.length === 0) {
      setcodeerrortext("Please enter verification code.");
    }
    if (code.length !== 0 && code.length === 6) {
      setloader(true);
      VerifySignupapi(verifyemail, code)
        .then((res) => {
          console.log("RES", res);
          if (res?.data?.success) {
            notify(res?.data?.data?.message, "success", 3000);
            setTimeout(() => {
              setloader(false);
              navigate("/verification-success");
              sessionStorage.removeItem("ver-email");
            }, 100);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setloader(false);
          setcodeerrortext("Verification code is invalid.");
        });
    }
  };

  return (
    <div
      className={`container-fluid ${styles.bgimage}`}
      style={{
        backgroundImage: `url(/Images/loginbgimage.png)`,
        fontSize: "13px",
        height: window.innerHeight,
      }}
    >
      <div
        className="d-flex justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <div
          className="text-center"
          style={{ marginTop: "3%", width: "540px" }}
        >
          <SvgZita width={150} height={80} />
          <div style={{ marginTop: "5%" }}>
            <div>
              <h4 className="fw-bold" style={{ marginBottom: "20px" }}>
                Verify Your Email Address
              </h4>
              <p style={{ padding: "0 50px" }}>
                We’ve sent a verification code to your email. Enter the code
                below to verify your account.
              </p>
              <p>
                If you haven't received the email within 3-5 minutes, please
                check your spam folder.
              </p>
            </div>
            <div
              style={{ marginTop: "35px", padding: "0 70px" }}
              className="text-start"
            >
              <div className="mb-3">
                <label htmlFor="email" className="form-label fw-bold">
                  Email address
                </label>
                <input
                  style={{
                    color: "#838385",
                    height: "44px",
                    maxWidth: "600px",
                    border: "1px solid #C9CACE",
                  }}
                  type="text"
                  className={`form-control ${styles.textbox1}`}
                  id="email"
                  name="email"
                  value={verifyemail}
                  disabled
                />
              </div>
              <div>
                <label
                  htmlFor="verificationCode"
                  className="form-label"
                  style={{ fontWeight: "700" }}
                >
                  Verification Code
                </label>
                <input
                  style={{
                    height: "44px",
                    maxWidth: "600px",
                  }}
                  type="text"
                  className={`form-control ${styles.textbox}`}
                  id="verificationCode"
                  name="verificationCode"
                  value={code}
                  onChange={codehandleChange}
                />
                <div
                  className="row"
                  style={{
                    marginBottom: submit && codeerrortext ? "10px" : undefined,
                    marginTop: "5px",
                  }}
                >
                  <div className="col-md-7  col-7">
                    {submit && codeerrortext && (
                      <div
                        style={{
                          color: "red",
                          paddingLeft: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {codeerrortext}
                      </div>
                    )}
                  </div>
                  <div className="col-md-5  col-5 text-end">
                    {isExpired ? (
                      <div
                        onClick={resendotp}
                        className="fw-bold "
                        style={{
                          color: "#581845",
                          textDecoration: "none",
                          paddingRight: "7px",
                          cursor: "pointer",
                        }}
                      >
                        Resend Code
                      </div>
                    ) : (
                      <div
                        style={{
                          marginRight: "5px",
                          color: "#581845",
                          fontSize: "12px",
                        }}
                      >
                        Resend Code in {timeLeft}sec
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "30px" }}>
                {loader ? (
                  <button
                    className={`btn ${styles.loginbtnloder}`}
                    type="button"
                  >
                    <span
                      className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                      role="status"
                      style={{ width: "15px", height: "15px" }}
                    ></span>
                  </button>
                ) : (
                  <button
                    className={`btn ${styles.loginbtn}`}
                    type="button"
                    onClick={() => handlesubmit()}
                  >
                    Verify Account
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <footer className={`text-center ${styles.rights}`}>
          <div>App Version {version}</div>
        </footer>
      </div>
    </div>
  );
};

export default Codeverification;
