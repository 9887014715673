import React from "react";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineController,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import SvgNoReport from "../../icons/SvgNoReport";

// Registering all necessary Chart.js components
ChartJS.register(
  LineController,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

interface ApiUsageData {
  lable: string[];
  day: number[];
  api_cals: number[];
  error_rate: number[];
  credits_log: number[];
}

interface MixedChartProps {
  data: ApiUsageData;
}

const MixedChart: React.FC<MixedChartProps> = ({ data }) => {
  // No Data Condition
  if ((data && data.day.length === 0) || data.api_cals.length === 0) {
    return (
      <div
        className="col-12 d-flex justify-content-center align-items-center flex-column"
        style={{ height: "390px" }}
      >
        <div style={{ padding: "0 0 10px 0" }}>
          <SvgNoReport />
        </div>
        <div style={{ color: "#999FAC", fontSize: "13px" }}>
          Looks like there are no report details available.
        </div>
      </div>
    );
  }

  // Chart Data
  const chartData = {
    labels: data.lable,
    datasets: [
      {
        label: "API Calls",
        data: data.api_cals,
        borderColor: "rgba(80, 80, 214)",
        backgroundColor: "#2d2de5", // Fill color for the area
        tension: 0.1,
        pointRadius: 5,
        pointHoverRadius: 7,
        fill: true, // Enable filling the area under the line
      },
      // {
      //   label: 'Credits',
      //   data: data.credits_log,
      //   borderColor: 'rgba(209, 232, 239, 0.7)',
      //   backgroundColor: 'rgba(209, 232, 239, 0.3)', // Fill color for the area
      //   tension: 0.1,
      //   pointRadius: 5,
      //   pointHoverRadius: 7,
      //   fill: true, // Enable filling the area under the line
      // },
      // {
      //   label: 'Error Rate',
      //   data: data.error_rate,
      //   borderColor: 'rgba(255, 99, 132, 0.7)', // Color for the error rate line
      //   backgroundColor: 'rgba(255, 99, 132, 0.3)', // Fill color for the area
      //   tension: 0.1,
      //   pointRadius: 5,
      //   pointHoverRadius: 7,
      //   fill: true, // Enable filling the area under the line
      // },
    ],
  };

  // Chart Options
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Weekly API Usage",
        color: "#333", // Set the font color
        padding: {
          top: 20,
          bottom: 20,
        },
        font: {
          size: 14,
          weight: 400,
          family: "Roboto, sans-serif", // Set the font family to Roboto
        },
      },
      legend: {
        display: false, // Disable the legend
      },
      tooltip: {
        paddingTop: 10,
        padding: 10, // Adjusts the padding inside the tooltip
        bodySpacing: 5, // Adjusts spacing between lines in the tooltip
        callbacks: {
          title: (tooltipItems: any) => {
            return `Day: ${data.lable[tooltipItems[0]?.dataIndex ?? 0]}`;
          },
          label: (tooltipItem: any) => {
            const apiCalls = tooltipItem.dataset.data[tooltipItem.dataIndex];
            const credits = data.credits_log[tooltipItem.dataIndex];
            const errorRate = data.error_rate[tooltipItem.dataIndex];
            return [
              ` API Calls: ${apiCalls}`,
              ` Credits: ${credits}`,
              ` Error Rate: ${errorRate}`,
            ];
          },
        },
        // Custom tooltip styling
        backgroundColor: "rgba(0, 0, 0, 0.8)", // Default background color
        borderColor: "transparent", // Optional: make border transparent
        borderWidth: 0, // Optional: set border width to 0
        animation: {
          duration: 0,
        },
        titleFont: {
          family: "Roboto, sans-serif", // Set font family for tooltip title
        },
        bodyFont: {
          family: "Roboto, sans-serif", // Set font family for tooltip body
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Days",
          font: {
            size: 13,
            family: "Roboto, sans-serif", // Set font family for x-axis title
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "API Calls",
          font: {
            size: 13,
            family: "Roboto, sans-serif", // Set font family for y-axis title
          },
        },
      },
    },
  };

  return <Chart type="line" data={chartData} options={options} height={100} />;
};

export default MixedChart;
