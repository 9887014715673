import React, { useState } from "react";
import styles from "./apiuseage.module.css";
import MixedChart from "./MixedChart";
import Button from "../../uikit/Button";
import SvgCsvDownload from "../../icons/SvgCsvDownload";
import DatePicker from "../OverviewModule/DatePicker";
import moment from "moment";
import RangeDatePicker from "../OverviewModule/RangeDatePicker";
import Loader from "../../../src/uikit/Loader";

interface ApiUsageData {
  lable: string[];
  day: number[];
  api_cals: number[];
  error_rate: number[];
  credits_log: number[];
}

interface ApiUseageProps {
  data: ApiUsageData;
  setDatepicker: (value: any) => void;
  ovralldays: any;
  download: () => void;
  setLoading: (value: any) => void;
  setisCurrentWeek: (value: any) => void;
  isCurrentWeek: any;
  isDatepicker: any;
  setButtonloader: (value: any) => void;
  isButtonloader: any;
  handleShow: () => void;
  handleHide: () => void;
  chartload: boolean;
  datefunction: (from: any, end: any) => void;
}

const ApiUseage: React.FC<ApiUseageProps> = ({
  data,
  setDatepicker,
  ovralldays,
  download,
  setLoading,
  setisCurrentWeek,
  isCurrentWeek,
  isDatepicker,
  setButtonloader,
  isButtonloader,
  handleShow,
  handleHide,
  chartload,
  datefunction,
}) => {
  const downloads = () => {
    download();
    setButtonloader(true);
  };

  console.log("sds", chartload);

  return (
    <div className="card my-3">
      <div className="card-body">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h5 className={styles.mainheading}>API Usage</h5>
          </div>
          <div>
            <div className="d-flex flex-row align-items-center">
              <div>
                {/* <DatePicker
                  setDatepicker={setDatepicker}
                  setLoading={setLoading}
                  setisCurrentWeek={setisCurrentWeek}
                  isDatepicker={isDatepicker}
                  handleShow={handleShow}
                  handleHide={handleHide}
                  datefunction={datefunction}
                /> */}
                  <RangeDatePicker  setDatepicker={setDatepicker} setinnerloader={setLoading}  isDatepicker={isDatepicker} setisCurrentWeek={setisCurrentWeek}/>
              </div>
              {data &&
              data?.day?.length === 0 &&
              data?.api_cals?.length === 0 ? (
                ""
              ) : (
                <div style={{ marginLeft: "7px" }}>
                  {isButtonloader ? (
                    <button
                      className={`btn ${styles.downloadbtnloder}`}
                      type="button"
                    >
                      <span
                        className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                        role="status"
                        style={{width:'15px',height:'15px',borderWidth:'2px',marginBottom:'3px'}}
                      ></span>
                    </button>
                  ) : (
                    <button
                      className={`btn d-flex flex-row justify-content-center align-items-center ${styles.downloadbtn}`}
                      type="button"
                      onClick={() => downloads()}
                    >
                      <div className="d-flex">
                        <SvgCsvDownload />
                      </div>
                      <span className="d-flex" style={{ marginLeft: 5 }}>
                        Download
                      </span>
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {isCurrentWeek ? (
                <h6
                  className={`${styles.subheading} m-0`}
                  style={{
                    padding: "5px 0px 9px 0px",
                  }}
                >
                  Over the last {ovralldays} days
                </h6>
              ) : (
                <h6
                  className={`${styles.subheading} m-0`}
                  style={{
                    padding: "5px 0px 9px 0px",
                  }}
                >
                  For the range:{" "}
                  {moment(isDatepicker?.startDate).format("DD/MM/YYYY")} -{" "}
                  {moment(isDatepicker?.endDate).format("DD/MM/YYYY")}
                </h6>
              )}
        <div style={{ height: "420px" }}>
          {chartload === true ? (
            // <div
            //   className="col-12 d-flex justify-content-center align-items-center flex-column"
            //   style={{ height: "-webkit-fill-available", color: "#581845" }}
            // >
            //   <span
            //     className={`spinner-border spinner-border-sm ${styles.loderboder}`}
            //     role="status"
            //   ></span>
            // </div>
            <div style={{height:'420px',width:'100%'}} >
            <Loader/>
           </div>
          ) : (
            <>
             

              <div style={{ height: "100%", width: "100%" }}>
                <MixedChart data={data} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiUseage;
