import React from "react";
import styles from "./errorrate.module.css";
import SvgNoReport from "../../icons/SvgNoReport";

import moment from "moment";
interface ErrorRateItem {
  date: string;
  statuscode: number;
  Count: number;
}

interface ErrorRateProps {
  data: ErrorRateItem[];
  ovralldays: any;
  isCurrentWeek: boolean;
  isDatepicker: any;
}

const ErrorRate: React.FC<ErrorRateProps> = ({
  data,
  ovralldays,
  isCurrentWeek,
  isDatepicker,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div
          className="d-flex flex-row align-items-center justify-content-between "
          style={{ marginBottom: "10px" }}
        >
          <h5 className={`${styles.mainheading} m-0`}>Error Rate</h5>
          {isCurrentWeek ? (
            <h6 className={`${styles.errorratesub} m-0`}>
              Over the last {ovralldays} days
            </h6>
          ) : (
            <h6 className={`${styles.errorratesub} m-0`}>
              For the range:{" "}
              {moment(isDatepicker.startDate).format("DD/MM/YYYY")} -{" "}
              {moment(isDatepicker.endDate).format("DD/MM/YYYY")}
            </h6>
          )}
        </div>
        <div className="">
          {data && data?.length === 0 ? (
            <div
              className="col-12 d-flex justify-content-center align-items-center flex-column"
              style={{ height: "300px" }}
            >
              <div style={{ padding: "0 0 9px 0" }}>
                <SvgNoReport />
              </div>
              <div style={{ color: "#999FAC", fontSize: "13px" }}>
                Looks like there are no report details available.
              </div>
            </div>
          ) : (
            <div>
              <table
                className={`table mb-0 ${styles.table} `}
                style={{ height: "300px" }}
              >
                <thead className={styles.stickyHeader}>
                  <tr>
                    <th className={styles.headingtable}>Date</th>
                    <th className={styles.headingtable}>Error Codes</th>
                    <th className={styles.headingtable}>Daily Total</th>
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        <td className={styles.valuetable}>{item.date}</td>
                        <td className={styles.valuetable}>{item.statuscode}</td>
                        <td className={styles.valuetable}>{item.Count}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorRate;
