export default function SvgApiProductDotGreen(): JSX.Element {
  return (
    <>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_78_928)">
          <path
            d="M6.49268 12.5938C9.85816 12.5938 12.5864 9.86549 12.5864 6.5C12.5864 3.13451 9.85816 0.40625 6.49268 0.40625C3.12719 0.40625 0.398926 3.13451 0.398926 6.5C0.398926 9.86549 3.12719 12.5938 6.49268 12.5938Z"
            fill="#C7C7CB"
          />
          <path
            d="M6.09658 12.1062C9.24331 12.1062 11.7942 9.55532 11.7942 6.40859C11.7942 3.26186 9.24331 0.710938 6.09658 0.710938C2.94985 0.710938 0.398926 3.26186 0.398926 6.40859C0.398926 9.55532 2.94985 12.1062 6.09658 12.1062Z"
            fill="#D4D4D8"
          />
          <path
            d="M2.43052 3.01621C2.88755 2.29512 3.86255 1.6959 4.87818 1.51309C5.13209 1.47246 5.38599 1.45215 5.59927 1.5334C5.76177 1.59434 5.8938 1.74668 5.8024 1.91934C5.7313 2.06152 5.53834 2.12246 5.38599 2.17324C4.43327 2.48762 3.61173 3.10914 3.05005 3.94043C2.84693 4.24512 2.54224 5.08809 2.16646 4.8748C1.77037 4.64121 1.85162 3.90996 2.43052 3.01621Z"
            fill="#E8E9F0"
          />
          <g clipPath="url(#clip1_78_928)">
            <path
              d="M6.49268 12.5938C9.85816 12.5938 12.5864 9.86549 12.5864 6.5C12.5864 3.13451 9.85816 0.40625 6.49268 0.40625C3.12719 0.40625 0.398926 3.13451 0.398926 6.5C0.398926 9.86549 3.12719 12.5938 6.49268 12.5938Z"
              fill="#689F38"
            />
            <path
              d="M6.09658 12.1062C9.24331 12.1062 11.7942 9.55532 11.7942 6.40859C11.7942 3.26186 9.24331 0.710938 6.09658 0.710938C2.94985 0.710938 0.398926 3.26186 0.398926 6.40859C0.398926 9.55532 2.94985 12.1062 6.09658 12.1062Z"
              fill="#7CB342"
            />
            <path
              d="M2.43052 3.01621C2.88755 2.29512 3.86255 1.6959 4.87818 1.51309C5.13209 1.47246 5.38599 1.45215 5.59927 1.5334C5.76177 1.59434 5.8938 1.74668 5.8024 1.91934C5.7313 2.06152 5.53834 2.12246 5.38599 2.17324C4.43327 2.48762 3.61173 3.10914 3.05005 3.94043C2.84693 4.24512 2.54224 5.08809 2.16646 4.8748C1.77037 4.64121 1.85162 3.90996 2.43052 3.01621Z"
              fill="#AED581"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_78_928">
            <rect width="13" height="13" fill="white" />
          </clipPath>
          <clipPath id="clip1_78_928">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
