import React, { useEffect, useState } from "react";
import DocumentTitle from "./DocumentTitle";
import DocumentCardmap from "./DocumentCardmap";
import DocumentSidebar from "./DocumentSidebar";
import { Documentations } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store/store";
import SvgArrow from "../../icons/SvgArrow";
import { useParams, useNavigate } from "react-router-dom";
import { Documentationdata } from "../../redux/reducers/Documentation";
import Loader from "../../uikit/Loader";
interface DocumentDatas {
  name: string;
  description: string;
  url: string;
}

const Documentation: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [view, setView] = useState<boolean>(true);

  const [documents, setDocuments] = useState<any[]>([]);
  const [index, setIndex] = useState<any>(0);

  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, data, isError } = useSelector(
    (state: RootState) => state.DocumentationData
  );

  useEffect(() => {
    if (data.length === 0) {
      dispatch(Documentationdata());
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (data) {
      setDocuments(data); // Ensure data is an array
    } else if (isError) {
      // Handle error
    }
  }, [data, isError]);

  // useEffect(() => {
  //   if (id) {
  //     setView(true);
  //     setIndex(parseInt(id));
  //     navigate("/documentation");
  //   }
  // }, [id]);

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="row">
          <div
            style={{
              position: "sticky",
              top: "0",
              zIndex: "3",
              background: "white",
              paddingBottom: "10px",
            }}
          >
            <DocumentTitle />
          </div>

          {/* {view ? ( */}
            <DocumentSidebar
              view={setView}
              data={data}
              index={index}
              changeindex={setIndex}
            />
          {/* // ) : (
          //   <DocumentCardmap
          //     view={setView}
          //     data={data}
          //     changeindex={setIndex}
          //   />
          // )} */}
        </div>
      </div>
    </>
  );
};

export default Documentation;
