import Button from "../../uikit/Button";
import { Link, useNavigate } from "react-router-dom";
import SvgBook from "../../icons/Svgbook";
import SvgApiProductsAdd from "../../icons/SvgApiProductsAdd";
import SvgApiProductDot from "../../icons/SvgApiProductDot";
import SvgApiProductDotGray from "../../icons/SvgApiProductDotGray";
import SvgApiProductDotGreen from "../../icons/SvgApiProductDotGreen";
import SvgApiProductDotYellow from "../../icons/SvgApiProductDotYellow";
import SvgApiProductDotRed from "../../icons/SvgApiProductDotRed";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import Styles from "./apiproduct.module.css";

interface PlanType {
  id: number;
  requests: number;
  validity: string | number;
  credits: number;
  price: string;
  stripe_id: string;
}

interface ResponseItemType {
  id: string;
  Active: boolean;
  colour: string;
  File_format: [];
  Plans: [];
  Resume_size: number;
  addon: PlanType;
  api_name: string;
  description: string;
  is_active: boolean;
  monthly: PlanType;
  name: string;
  version: string;
  yearly: PlanType;
  url: string;
  validation: string;
}

type Props = {
  data?: ResponseItemType[];
};
export default function ApiProductCard({ data }: Props): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <div className="contaniner">
        <div className="row">
          {data?.map((value, i) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5" key={i}>
              <div
                className={`card d-flex justify-content-between ${Styles.cardheight}`}
                style={{
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  padding: "10px 15px 15px 15px",
                }}
              >
                <div>
                  <div
                    className="d-flex align-items-center text-center"
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <span style={{ paddingTop: "3px", marginRight: "5px" }}>
                      {value?.name}
                    </span>
                    {value?.colour === "grey" && (
                      <CustomTooltip
                        title={"Yet to be Purchased"}
                        direction="right"
                      >
                        <SvgApiProductDotGray />
                      </CustomTooltip>
                    )}
                    {value?.colour === "green" && (
                      <CustomTooltip
                        title={`Purchased on ${value?.validation}`}
                        direction="right"
                      >
                        <SvgApiProductDotGreen />
                      </CustomTooltip>
                    )}
                    {value?.colour === "yellow" && (
                      <CustomTooltip
                        title={`Expiring on ${value?.validation}`}
                        direction="right"
                      >
                        <SvgApiProductDotYellow />
                      </CustomTooltip>
                    )}
                    {value?.colour === "red" && (
                      <CustomTooltip title={"Expired"} direction="right">
                        <SvgApiProductDotRed />
                      </CustomTooltip>
                    )}
                  </div>
                  <div
                    className="mt-1 d-flex flex-row align-items-center"
                    style={{
                      fontSize: "11px",
                      color: "#581845",
                    }}
                  >
                    <div className="d-flex flex-row py-2">
                      <span style={{ padding: "0px" }}>
                        Version {value?.version}
                      </span>

                      <div
                        className="d-flex align-items-center"
                        style={{ fontSize: "5px", padding: "0px 10px" }}
                      >
                        {/* <FontAwesomeIcon icon={faCircle} /> */}
                        <SvgApiProductDot />
                      </div>
                    </div>

                    <span style={{ padding: "0px" }}>
                      {`Monthly: $${value?.monthly.price}/${value?.monthly.requests} requests.`}
                    </span>
                  </div>
                  <div className="mt-2" style={{ fontSize: "13px" }}>
                    {value?.description}
                  </div>
                </div>

                <div>
                  <div className="d-flex justify-content-center mt-3 ">
                    <div style={{ paddingRight: "5px" }}>
                      <SvgBook />
                    </div>
                    <div>
                      <Link
                        to={`/documentation`}
                        className="fw-bold mt-4"
                        style={{
                          color: "#581845",
                          textDecoration: "none",
                          fontSize: "13px",
                        }}
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>

                  <div className=" d-flex align-items-center justify-content-center mt-3">
                    {value?.colour === "green" || value?.colour === "yellow" ? (
                      <Button
                        name="Add Credits"
                        variant="secondary"
                        width="115px"
                        svg={<SvgApiProductsAdd />}
                        onClick={() => {
                          sessionStorage.removeItem(value?.id);
                          sessionStorage.removeItem("dis");
                          navigate(`/api-products/${value?.id}`);
                        }}
                      />
                    ) : (
                      <Button
                        name="Purchase Now"
                        variant="primary"
                        width="115px"
                        borderRadius="5px"
                        onClick={() => {
                          sessionStorage.removeItem(value?.id);
                          sessionStorage.removeItem("dis");
                          navigate(`/api-products/${value?.id}`);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
