import React, { useState } from "react";
import "./ChangePasswordModal.modal.css";
import ChangePasswordFirstField from "./ChangePwdFirstField";
import ChangePasswordSecondField from "./ChangePwdSecondField";

interface ChangePasswordProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({
  showModal,
  setShowModal,
}) => {
  const [ChangePasswordMode, setChangePasswordMode] = useState<boolean>(false);
  const [currentPwd, setCurrentPwd] = useState<string>("");
  if (!showModal) return null;
  return (
    <div
      className="modal show modal-overlay"
      tabIndex={-1}
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="container">
            <div className="modal-body">
              <p style={{ fontSize: "14px", fontWeight: 700 }}>
                Change Password
              </p>
              {/* {!ChangePasswordMode ? (
                <ChangePasswordFirstField
                  setShowModal={setShowModal}
                  setChangePasswordMode={setChangePasswordMode}
                  setCurrentPwd={setCurrentPwd}
                />
              ) : ( */}
              <ChangePasswordSecondField
                setShowModal={setShowModal}
                setChangePasswordMode={setChangePasswordMode}
                currentPwd={currentPwd}
              />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
