
export const ModuleName = "Billing"
export const NoInvoiceText = "Looks like no payment details are available."


export const roughdata  = [
    {
      "expired_date": "2024-08-20 16:30:22.619270",
      "total": "120",
      "credits": "340",
      "email": "pugazhe9202@gmail.com",
      "name": "AI Interview Question Generation",
      "next_billing_date": "2024-08-21 16:30:22.619270",
      "paid": true,
      "payment_date": "2024-08-03 16:30:22.619270",
      "api_name": "profile_summary",
      "download_url": "https://pay.stripe.com/invoice/acct_1IsaJzJK7wwywY1K/test_YWNjdF8xSXNhSnpKSzd3d3l3WTFLLF9RYVFJQ3Rsb21tdXRoaldNM3V0eVpZd2ZjT0cxNndTLDExMzMyOTAzNg02008ZFvrF6i/pdf?s=ap",
      "invoice": "#123456",
      "description": "use buy a profile summary",
      "type": "api service"
    },
    {
      "invoice_item": [
        {
          "invoice_item": "ii_1PmcJPJK7wwywY1Kw7RNh9W9",
          "description": "Additional Resume Unlock"
        },
        {
          "invoice_item": "ii_1PmcJPJK7wwywY1Kji7xqEHC",
          "description": "Additional AI Resume Parsing"
        },
        {
          "invoice_item": "ii_1PmcJPJK7wwywY1KNVYsLhwZ",
          "description": "Additional Jobs"
        }
      ],
      "status": "paid",
      "total": 344,
      "invoice_pdf": "https://pay.stripe.com/invoice/acct_1IsaJzJK7wwywY1K/test_YWNjdF8xSXNhSnpKSzd3d3l3WTFLLF9RZHU3Z1JiNVRlUTZibG9SclJ3TndZbW9BT3hBN2NELDExMzk4NzcxOQ02008C8lNbql/pdf?s=ap",
      "receipt_url": "https://invoice.stripe.com/i/acct_1IsaJzJK7wwywY1K/test_YWNjdF8xSXNhSnpKSzd3d3l3WTFLLF9RZHU3Z1JiNVRlUTZibG9SclJ3TndZbW9BT3hBN2NELDExMzk4NzcxOQ02008C8lNbql?s=ap",
      "invoice": "ZITA-7379",
      "email": "pugazhendhij9202@gmail.com",
      "next_billing_date": "2024-09-12 13:59:59"
    },
    {
      "expired_date": "2024-08-20 16:30:22.619270",
      "total": "120",
      "credits": "340",
      "email": "nanthini852001@gmail.com",
      "name": "Resume Parser",
      "next_billing_date": "2024-08-21 16:30:22.619270",
      "paid": true,
      "payment_date": "2024-08-03 16:30:22.619270",
      "api_name": "resume_parser",
      "download_url": "https://pay.stripe.com/invoice/acct_1IsaJzJK7wwywY1K/test_YWNjdF8xSXNhSnpKSzd3d3l3WTFLLF9RYVFJQ3Rsb21tdXRoaldNM3V0eVpZd2ZjT0cxNndTLDExMzMyOTAzNg02008ZFvrF6i/pdf?s=ap",
      "invoice": "#123457",
      "description": "user buy a resume parser",
      "type": "api service"
    },
    {
      "expired_date": "2024-08-20 16:30:22.619270",
      "total": "120",
      "credits": "340",
      "email": "nanthini852001@gmail.com",
      "name": "Jd Parser",
      "next_billing_datse": "2024-08-21 16:30:22.619270",
      "paid": true,
      "payment_date": "2024-08-03 16:30:22.619270",
      "api_name": "jd_parser",
      "download_url": "https://pay.stripe.com/invoice/acct_1IsaJzJK7wwywY1K/test_YWNjdF8xSXNhSnpKSzd3d3l3WTFLLF9RYVFJQ3Rsb21tdXRoaldNM3V0eVpZd2ZjT0cxNndTLDExMzMyOTAzNg02008ZFvrF6i/pdf?s=ap",
      "invoice": "#123458",
      "description": "user buy a jd parser",
      "type": "api service"
    }
  ]
  

export interface BillingTableInterface {
    download_url: any
    expired_date ?: string | any, 
    total ?: string | any,
    credits?: number | any,
    email ?: string | any,
    name?: string | any,
    next_billing_date ?: string,
    paid ?: boolean,
    payment_date ?: string | any,
    api_name ?: string,
    invoice_pdf ?: string | any,
    invoice ?: string,
    description ?: string,
    type ?: string,
    invoice_item ?: any
}


