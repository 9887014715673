import * as React from "react";
const SvgView = (props: any) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 4C7.5 4.827 6.827 5.5 6 5.5C5.173 5.5 4.5 4.827 4.5 4C4.5 3.173 5.173 2.5 6 2.5C6.827 2.5 7.5 3.173 7.5 4ZM12 3.7755C12 3.7755 9.874 8 6.0075 8C2.4175 8 0 3.7755 0 3.7755C0 3.7755 2.223 0 6.0075 0C9.8545 0 12 3.7755 12 3.7755ZM8.5 4C8.5 2.6215 7.3785 1.5 6 1.5C4.6215 1.5 3.5 2.6215 3.5 4C3.5 5.3785 4.6215 6.5 6 6.5C7.3785 6.5 8.5 5.3785 8.5 4Z"
      fill="#581845"
    />
  </svg>
);
export default SvgView;
