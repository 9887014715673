import React, { useEffect } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import yaml from "yaml";

const Resume = `
openapi: 3.0.1
info:
  title: JD Generation
  version: 1.0.0
servers:
  - url: 'https://uotxnvwte6.execute-api.us-east-1.amazonaws.com/prod'
    description: API Server
tags:
  - name: Job Description
    description: Operations related to job description generation
components:
  securitySchemes:
    ApiKeyAuth:
      type: apiKey
      in: header
      name: x-api-key
  responses:
    ErrorResponse:
      description: Error response
      content:
        application/json:
          schema:
            type: object
            properties:
              error:
                type: string
                example: An error occurred
paths:
  /jd_generation:
    post:
      tags:
        - Job Description
      summary: Generate Job Description
      description: |
        Generates a job description based on provided input parameters.
        
        **Sample Input:**
        
        - job_title: Software developer
        - industry_type: Technology
        - min_experience: 2
        - max_experience: 10
        - skills: ['react', 'html', 'css']
        - overview: A Software Engineer is responsible for designing, developing, testing, and maintaining software applications or systems. They work closely with stakeholders to understand requirements, write clean and efficient code, and ensure the performance, scalability, and security of the applications they develop.
        - nice_to_have: ['python']
      operationId: generateJobDescription
      security:
        - ApiKeyAuth: []
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                job_title:
                  type: string
                  description: Job title for the description
                  default: ''
                industry_type:
                  type: string
                  description: Industry type
                  default: ''
                min_experience:
                  type: integer
                  description: Minimum experience required
                  default: ''
                max_experience:
                  type: integer
                  description: Maximum experience allowed
                  default: ''
                skills:
                  type: string
                  description: List of required skills as a JSON string
                  default: ''
                overview:
                  type: string
                  description: Overview of the job role
                  default: ''
                nice_to_have:
                  type: string
                  description: List of nice-to-have skills as a JSON string
                  nullable: true
                  default: ''
            examples:
              SamplePayload:
                summary: Example payload
                value:
                  job_title: Software developer
                  industry_type: Technology
                  min_experience: 2
                  max_experience: 10
                  skills: ['react', 'html', 'css']
                  overview: A Software Engineer is responsible for designing, developing, testing, and maintaining software applications or systems. They work closely with stakeholders to understand requirements, write clean and efficient code, and ensure the performance, scalability, and security of the applications they develop.
                  nice_to_have: ['python']
      responses:
        '200':
          description: Job description generated successfully
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: true
        '400':
          description: Bad Request
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Invalid parameters
        '401':
          description: Not Authorized
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Unauthorized access. Please provide valid credentials.
        '403':
          description: Forbidden (Insufficient Resources, Invalid Content)
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Forbidden. Insufficient resources or invalid content.
        '404':
          description: User Not Found
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User not found.
        '409':
          description: Conflict (User Already Exists)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: User already exists.
        '413':
          description: Content Too Large
          content:
            application/json:
              schema:
                type: object
                properties:
                  message:
                    type: string
                    example: Request entity too large.
        '415':
          description: Unsupported Media Type
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: Unsupported media type.
        '429':
          description: Too Many Requests (OTP Limit Exceeded)
          content:
            application/json:
              schema:
                type: object
                properties:
                  error:
                    type: string
                    example: OTP limit exceeded. Please try again later.
        '500':
          description: Internal Server Error
          content:
            application/json:
              schema:
                type: object
                properties:
                  success:
                    type: boolean
                    example: false
                  data:
                    type: object
                    properties:
                      error:
                        type: string
                        example: Internal server error.


`;

const spec = yaml.parse(Resume);

const JdGenerationDoc = () => {
  return (
    <div>
      <style>
        {`
          .swagger-container {
            margin: 20px auto;
            max-width: 90%;
            box-sizing: border-box;
          }

          .swagger-ui .info {
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
          }

          .swagger-ui .opblock-summary-description {
            white-space: normal; /* Prevents text from overflowing */
          }

          /* Custom horizontal layout for method call header */
          .swagger-ui .opblock-summary {
            display: flex; /* Use flexbox for horizontal layout */
            align-items: center; /* Align items vertically in the center */
            justify-content: flex-start; /* Align items to the start horizontally */
            white-space: nowrap; /* Prevent text from wrapping */
            overflow: hidden; /* Hide overflow */
            max-width: 100%; /* Ensure it uses the full width of the container */
          }

          .swagger-ui .opblock-summary-method {
            margin-right: 12px; /* Space between method and path */
            font-weight: bold; /* Make the method text bold */
            white-space: nowrap; /* Prevent wrapping for method */
          }

          .swagger-ui .opblock-summary-path {
            margin-right: 12px; /* Space between path and description */
            white-space: nowrap; /* Prevent wrapping for path */
            text-overflow: ellipsis; /* Add ellipsis if the path is too long */
            overflow: hidden; /* Hide any overflowing text */
            max-width: 300px; /* Set a maximum width for the path */
            min-width: 150px; /* Set a minimum width for the path to avoid collapse */
          }

          .swagger-ui .opblock-summary-description {
            flex: 1; /* Allow the description to take the remaining space */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the description is too long */
            max-width: 400px; /* Set a maximum width for the description */
            min-width: 200px; /* Set a minimum width for the description to avoid collapse */
          }

          .swagger-ui .opblock-summary-controls {
            margin-left: auto; /* Push the controls to the far right */
            display: flex; /* Use flexbox for any control buttons */
            align-items: center; /* Align controls vertically in the center */
            white-space: nowrap; /* Prevent controls from wrapping */
          }

          /* SVG icons */
          .swagger-ui svg {
            flex-shrink: 0; /* Prevent icons from shrinking */
          }

          /* Static header setup for response table */
          .swagger-ui .response-col_description {
            width: 300px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          .swagger-ui .response-col_status {
            width: 80px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          .swagger-ui .response-col_links {
            width: 100px; /* Set fixed width */
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis if the text is too long */
          }

          /* Fix table headers to ensure consistent layout */
          .swagger-ui .table th {
            white-space: nowrap; /* Prevent wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis for overflow */
          }

          .swagger-ui .table td {
            white-space: nowrap; /* Prevent wrapping */
            overflow: hidden; /* Hide overflow */
            text-overflow: ellipsis; /* Add ellipsis for overflow */
          }

          /* Ensure the table itself has a fixed layout */
          .swagger-ui .table {
            table-layout: fixed; /* Ensure fixed layout */
            width: 100%; /* Use full width */
          }

          /* Override the problematic rule for parameters-col_description */
          .swagger-ui .parameters-col_description {
            margin-bottom: 0; /* Remove the margin if it causes issues */
            width: auto; /* Set width to auto to allow proper resizing */
            white-space: normal; /* Allow text wrapping */
            overflow-wrap: break-word; /* Break long words */
          }


          .swagger-ui .opblock-summary-description {
            white-space: normal; /* Prevents text from overflowing */
          }
          /* Hide "No links" text */
          .swagger-ui .response-col_links i {
            display: none;
          }

          /* Optionally hide the entire "Links" column if needed */
          .swagger-ui .response-col_links {
            display: none;
          }
        `}
      </style>
      <SwaggerUI
        spec={spec}
        docExpansion="list" // Ensures all operations are expanded by default
      />
    </div>
  );
};

export default JdGenerationDoc;
