const SvgClose: React.FC = (): JSX.Element => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9L9 1M1 1L9 9"
        stroke="#581845"
        stroke-width="1.25"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default SvgClose;
