import React, { useState, useEffect, useRef, useCallback } from "react";
import Loader from "../../uikit/Loader";
import { NoInvoiceText, BillingTableInterface } from "./BillingTypes";
import BillingTable from "./BillingTable";
import SvgNoInvoice from "../../icons/SvgNoInvoice";
import styles from "./billing.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store/store";
import { billingapi } from "../../redux/reducers/BillingSlice";


const BillingModule: React.FC = (): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    
    // Here its make a request and Get the response
    const { isLoading, items } = useSelector((state: RootState) => state.Billing);
    console.log("isLoading", isLoading, "items", items)
    useEffect(() => {
        if (isLoading) {
            // if(noinvoice === false){
            dispatch(billingapi({}))
            // if (items.length > 0 && items && isLoading) {
            //     setloader(false)
            // } else {
            //     if (items.length > 0 && items && isLoading) {
            //         setloader(false)
            //     }
            //     if(items.length === 0){
            //         setnoInvoice(true)
            //     }
            //     setloader(false)
            // }
            // }
            // setloader(false)
        } 

    }, [dispatch, items])


    // Here its rendering the Table Messages  
    // if (isLoading === true) {
    //     return (
    //         <div
    //             className="d-flex justify-content-center align-items-center flex-column"
    //             style={{
    //                 width: "100%",
    //                 position: "absolute",
    //                 top: "50%",
    //                 overflow: "hidden",
    //             }}>
    //             <Loader />
    //         </div>
    //     );
    // }
    return (
        <>
            {items && items.length > 0 ? (
                <BillingTable
                dataList={items}
                />
                ) : (<NodataList />)}
        </>
    );
};


export const NodataList: React.FC = (): JSX.Element => {
    return (
        <>
            <div className="container p-3 mt-2">
                <div className={styles.nodata}>
                    <SvgNoInvoice />
                    <span
                        className={styles.noinvoicetext}
                    >
                        {NoInvoiceText}
                    </span>
                </div>
            </div>
        </>
    )


}

export default BillingModule;


