import React from "react";
import styles from "./analyticstitle.module.css";

import SvgAnalytics from "../../icons/SvgAnalytics";

const AnalyticsTitle = () => {
  return (
    <div
      className="d-flex flex-row align-items-center justify-content-between mt-3"
      style={{ marginLeft: "3px" }}
    >
      <div className="d-flex flex-row align-items-center justify-content-ceter">
        <SvgAnalytics width={18} height={18} />
        <h4 className={`${styles.mainheading} mb-0 `}>Analytics</h4>
      </div>
    </div>
  );
};

export default AnalyticsTitle;
