// Loader.tsx
import React, { useEffect } from "react";
import { verifyToken } from "../configuration";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

const Loader: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleVerifyTokens = async () => {
      const accessToken = sessionStorage.getItem("access_token");
      const idToken = sessionStorage.getItem("id_token");

      if (accessToken && idToken) {
        try {
          const accessTokenPayload = await verifyToken(accessToken, "access");
          const idTokenPayload = await verifyToken(idToken, "id");
          if (!accessTokenPayload && !idTokenPayload) {
            navigate("/");
          }
        } catch (err) {
          console.error("Error verifying tokens:", err);
          navigate("/");
        }
      } else {
        console.error("No access token or ID token found in session storage.");
        navigate("/");
      }
    };
    handleVerifyTokens();
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100%" }}
    >
      <div
        className="spinner-border"
        role="status"
        style={{
          width: "3rem",
          height: "3rem",
          borderWidth: "0.2em",
          borderColor: "#581845 transparent transparent transparent",
          borderTopColor: "#581845", // Set the spinner's color
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
