import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { applicationapi } from "../../api";

interface GetOtpState {
  name: string;
  isLoading: boolean;
  isError: boolean;
  email: string;
  password: string;
}

interface OtpResponse {
    success: boolean;
}

const initialState: GetOtpState = {
  name: "getOtp",
  isLoading: false,
  isError: false,
  email: "",
  password: "",
};

export const getOtp = createAsyncThunk<OtpResponse, FormData>(
  "getOtp",
  async (formData, thunkAPI) => {  
    const url = "/send_otp_change_pass"
    const idToken = sessionStorage.getItem("id_token");
    try {
      const response = await applicationapi.put<OtpResponse>(
       url,
        formData,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error :  any) {
      // console.log(error);
      
      if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({ message: 'Entered password does not match the current password.', status: 401 });
          case 404:
            return thunkAPI.rejectWithValue({ message: 'Resource not found.', status: 404 });
          case 500:
            return thunkAPI.rejectWithValue({ message: 'Internal server error. Please try again later.', status: 500 });
          default:
            return thunkAPI.rejectWithValue({ message: error.response?.data?.data?.message || 'Failed to fetch OTP', status: error.response.status });
        }
      } else {
        return thunkAPI.rejectWithValue({ message: 'Network error or server did not respond.', status: null });
      }
    }
  }
);


export const validateOtp = createAsyncThunk<OtpResponse, FormData>(
  "validateOtp",
  async (formData, thunkAPI) => {
  const idToken = sessionStorage.getItem("id_token");
  const url="/change_password_without_otp"
   try {
     //console.log(formData);
    
     const response = await applicationapi.put(url,
       formData,
       {
         headers: {
           Authorization: `Bearer ${idToken}`,
           "Content-Type": "application/json",
         },
       }
     )
     return response.data
   } catch (error : any) {
   if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({ message: 'Entered password does not match the current password.', status: 401 });
          case 404:
            return thunkAPI.rejectWithValue({ message: 'Resource not found.', status: 404 });
          case 500:
            return thunkAPI.rejectWithValue({ message: 'Internal server error. Please try again later.', status: 500 });
          default:
            return thunkAPI.rejectWithValue({ message: error.response?.data?.data?.message || 'Failed to fetch OTP', status: error.response.status });
        }
      } else {
        return thunkAPI.rejectWithValue({ message: 'Network error or server did not respond.', status: null });
      }
   }
  }
)

const changePasswordSlice = createSlice({
  name: "ChangePassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOtp.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;    
      })
      .addCase(getOtp.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default changePasswordSlice.reducer;
