export default function SvgCopySign(): JSX.Element {
  return (
    <>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_86_3395)">
          <path
            d="M6.5 0C2.91038 0 0 2.91038 0 6.5C0 10.0896 2.91038 13 6.5 13C10.0896 13 13 10.0896 13 6.5C13 2.91038 10.0896 0 6.5 0ZM5.43888 9.20833L3.00137 6.86888L3.757 6.09158L5.42533 7.68246L9.22838 3.79167L9.99862 4.55488L5.43888 9.20833Z"
            fill="#34CC65"
          />
        </g>
        <defs>
          <clipPath id="clip0_86_3395">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
