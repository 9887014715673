import * as React from 'react';
const SvgLock = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={15}
    fill="none"
    stroke="#000"
    viewBox='0 0 14 15'
    {...props}
  >
    <path
      stroke={props.stroke || "#000"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.813}
      d="M4.25 6.056V4.61C4.25 2.617 5.145 1 7.139 1s2.889 1.617 2.889 3.611v1.445m-2.89 4.152v-.36m2.89.36v-.36m-5.778.36v-.36M1 11.687V8.368c0-.81 0-1.214.157-1.522.139-.272.36-.493.632-.632.309-.157.713-.157 1.522-.157h7.656c.809 0 1.213 0 1.522.157.272.138.493.36.631.631.158.31.158.714.158 1.523v3.322c0 .809 0 1.213-.158 1.522-.138.272-.36.493-.63.632-.31.157-.714.157-1.523.157H3.31c-.809 0-1.213 0-1.522-.157a1.45 1.45 0 0 1-.632-.632C1 12.903 1 12.498 1 11.69"
    />
  </svg>
);
export default SvgLock;