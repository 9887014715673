import * as React from 'react';
const SvgOverviewIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="#1E1E1E"
    viewBox="0 0 13 13"
    {...props}
  >
    <path
      fill={props.fill}
      d="M7.719 4.063V0H13v4.063zM0 6.5V0h5.281v6.5zM7.719 13V6.5H13V13zM0 13V8.938h5.281V13zm.813-7.312h3.656V.813H.812zm7.718 6.5h3.656V7.312H8.532zm0-8.938h3.656V.813H8.532zM.812 12.188H4.47V9.75H.812z"
    />
  </svg>
);
export default SvgOverviewIcon;