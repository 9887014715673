import React from "react";
import SvgDocument from "../../icons/SvgDocument";
import styles from "./document.module.css";
const DocumentTitle = () => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-between mt-3">
      <div className="d-flex flex-row align-items-center justify-content-ceter">
        <SvgDocument width={18} height={18} />
        <h4 className={`${styles.mainheading} mb-0 `}>Documentations</h4>
      </div>
    </div>
  );
};

export default DocumentTitle;
