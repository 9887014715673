import React, { useEffect, useState } from "react";
import SvgProducts from "../../icons/SvgProducts";
import ApiProductCard from "./Apiproductcard";
import Loader from "../../uikit/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getApiProducts,
  varifiedCheckoutApi,
} from "../../redux/reducers/ApiProductsSlice";
import { AppDispatch, RootState } from "../../redux/store/store";
import { notify } from "../../uikit/toast";
import { useNavigate } from "react-router-dom";
import { billingapi } from "../../redux/reducers/BillingSlice";

const ApiProducts: React.FC = (): JSX.Element => {
  const [apiDelay, setApiDelay] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { isLoading, data } = useSelector(
    (state: RootState) => state.ApiProducts
  );

  useEffect(() => {
    const useQuery = new URLSearchParams(window.location.search);
    const session_id = useQuery.get("session_id");
    if (session_id) {
      const payload = { session_id: session_id };
      setApiDelay(true);
      setTimeout(() => {
        dispatch(varifiedCheckoutApi(payload as any))
          .then((response) => {
            if (response.payload.success) {
              dispatch(billingapi({}));
              sessionStorage.removeItem("dis");
              notify(`${response.payload.data?.message}`, "success", 3000);
            } else {
              notify("Somthing went wrong!", "error", 3000);
            }
            dispatch(getApiProducts());
            navigate("/api-products");
          })
          .catch((error) => {
            // console.log(error);
            notify("Failed to verify session.", "error", 3000);
          })
          .finally(() => {
            setApiDelay(false);
          });
      }, 3000);
    }
  }, [dispatch, navigate]);

  // Fetch API products if data is not available
  useEffect(() => {
    if (!data?.length) dispatch(getApiProducts());
  }, [dispatch, data]);

  // console.log("ApiProducts", data);

  if (isLoading || apiDelay) return <Loader />;
  return (
    <>
      <div className="container p-3 mt-1">
        <div className="d-flex mb-3 align-items-center">
          <SvgProducts width={20} height={20} />
          <span
            className="d-flex align-items-center ms-2"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              paddingBottom: "3px",
            }}
          >
            API Products
          </span>
        </div>
        <div>
          <ApiProductCard data={data} />
        </div>
      </div>
    </>
  );
};

export default ApiProducts;
