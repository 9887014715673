import * as React from 'react';
const SvgDocument = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={15}
    fill="none"
    stroke="#1E1E1E"
    viewBox="0 0 13 15"
    {...props}
  >
    <path
      stroke={props.stroke || "#1E1E1E"}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.857 7.5H7.5M2.857 9.357h6.5m-6.5 1.857h2.786m6.5.929v-6.5L7.5 1H2.857A1.857 1.857 0 0 0 1 2.857v9.286A1.857 1.857 0 0 0 2.857 14h7.429a1.857 1.857 0 0 0 1.857-1.857"
    />
    <path
      stroke={props.stroke || "#1E1E1E"}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.5 1v2.786a1.857 1.857 0 0 0 1.857 1.857h2.786"
    />
  </svg>
);
export default SvgDocument;