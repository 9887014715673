import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { applicationapi } from "../../api";
import { BillingTableInterface } from "../../components/Billing/BillingTypes";
import { boolean } from "yup";
import { faL } from "@fortawesome/free-solid-svg-icons";


interface BillingState {
  isLoading: boolean;
  isdownLoading : boolean;
  isError: boolean;
  items : BillingTableInterface[],
  download_url:string,
  is_download_url : string,
  message:string,
  length : number
}

interface BillingResponse {
    success: boolean;
}

interface DownloadInvoice{
    invoice_id ?: string

}


const initialState: BillingState = {
    isLoading: false,
    isdownLoading : false,
    isError: false,
    items: [],
    download_url : '',
    is_download_url : '',
    length : 0,
    message : ''
  };

export const billingapi = createAsyncThunk<BillingResponse, DownloadInvoice>(
  "BillingData",
  async (invoice_id, thunkAPI) => {  
    const url = "/invoice"
    const idToken = sessionStorage.getItem("id_token");
    try {
        const params = invoice_id
        const config = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          params: params,
        };
        const response =  await applicationapi.get(url, config);
        return response.data
    } catch (error :  any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({ message: 'Incorrect password. Please try again.', status: 401 });
          case 404:
            return thunkAPI.rejectWithValue({ message: 'Resource not found.', status: 404 });
          case 500:
            return thunkAPI.rejectWithValue({ message: 'Internal server error. Please try again later.', status: 500 });
          default:
            return thunkAPI.rejectWithValue({ message: error.response?.data?.data?.message || 'Failed to fetch OTP', status: error.response.status });
        }
      } else {
        return thunkAPI.rejectWithValue({ message: 'Network error or server did not respond.', status: null });
      }
    }
  }
);

export const downloadinvoiceapi = createAsyncThunk<BillingResponse, DownloadInvoice>(
  "DownloadInvoice",
  async (invoice_id, thunkAPI) => {  
    const url = "/invoice"
    const idToken = sessionStorage.getItem("id_token");
    try {
        const params = invoice_id
        const config = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          params: params,
        };
        const response =  await applicationapi.get(url, config);
        return response.data
    } catch (error :  any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            return thunkAPI.rejectWithValue({ message: 'Incorrect password. Please try again.', status: 401 });
          case 404:
            return thunkAPI.rejectWithValue({ message: 'Resource not found.', status: 404 });
          case 500:
            return thunkAPI.rejectWithValue({ message: 'Internal server error. Please try again later.', status: 500 });
          default:
            return thunkAPI.rejectWithValue({ message: error.response?.data?.data?.message || 'Failed to fetch OTP', status: error.response.status });
        }
      } else {
        return thunkAPI.rejectWithValue({ message: 'Network error or server did not respond.', status: null });
      }
    }
  }
);


const BillingSlice = createSlice({
  name: "Billing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(billingapi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(billingapi.fulfilled, (state, action:PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = false;    
        state.items = action.payload.data.item;
        state.message = action.payload.data.message;
        state.is_download_url = action.payload.data.download_url;
        state.length = action.payload.data.item_length;
      })
      .addCase(billingapi.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;

      // download invoice api
      }).addCase(downloadinvoiceapi.pending, (state) => {
        state.isdownLoading = true;
      })
      .addCase(downloadinvoiceapi.fulfilled, (state, action:PayloadAction<any>) => {
        state.isdownLoading = false;
        state.isError = false;    
        state.items = action.payload.data.item;
        state.message = action.payload.data.message;
        state.download_url = action.payload.data.download_url;
        state.length = action.payload.data.item_length;
      })
      .addCase(downloadinvoiceapi.rejected, (state) => {
        state.isdownLoading = false;
        state.isError = true;
      });
  },
});

export default BillingSlice.reducer;

