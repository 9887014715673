const SvgEyeOpen: React.FC = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_91_4705)">
        <path
          d="M12.5 9.99967C12.5 11.378 11.3783 12.4997 10 12.4997C8.62167 12.4997 7.5 11.378 7.5 9.99967C7.5 8.62134 8.62167 7.49967 10 7.49967C11.3783 7.49967 12.5 8.62134 12.5 9.99967ZM20 9.62551C20 9.62551 16.4567 16.6663 10.0125 16.6663C4.02917 16.6663 0 9.62551 0 9.62551C0 9.62551 3.705 3.33301 10.0125 3.33301C16.4242 3.33301 20 9.62551 20 9.62551ZM14.1667 9.99967C14.1667 7.70217 12.2975 5.83301 10 5.83301C7.7025 5.83301 5.83333 7.70217 5.83333 9.99967C5.83333 12.2972 7.7025 14.1663 10 14.1663C12.2975 14.1663 14.1667 12.2972 14.1667 9.99967Z"
          fill="#581845"
        />
      </g>
      <defs>
        <clipPath id="clip0_91_4705">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgEyeOpen;
