import { useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "./uikit/Button";

export default function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [screenHeight, setScreenHeight] = useState<any>(window.innerHeight)

  const handleManualCheck = () => {
    setIsOnline(navigator.onLine);
    if (navigator.onLine) {
      if (!isOnline) {
        // Check the internet connection and set online status
        setIsOnline(navigator.onLine);
      } else {
        // Reload the current page
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isOnline ? (
        <div
          style={{
            width: "100%",
            height: window.innerHeight,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3>Are you online?</h3>
          <span>Please check your internet connection and try again.</span>
          <button
            className="retryButton"
            style={{ borderRadius: "20px", marginTop: "10px" }}
            onClick={handleManualCheck}
          >
            Retry
          </button>
        </div>
      ) : (
        <div>
          <AppRoutes />
          <ToastContainer
            position="top-right"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      )}
    </>
  );
}
