import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import Button from "../../uikit/Button";
import SvgZita from "../../icons/SvgZita";
import robort from "../../NotFound.json";

export default function NotFound(): JSX.Element {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: robort,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="vh-100">
        <SvgZita />
        <hr className="border border-secondary border-2 opacity-10" />
        <div className="row g-0">
          <div className="col-6 d-flex flex-column justify-content-center align-items-center">
            <Lottie
              options={defaultOptions}
              height={600}
              width={600}
              isClickToPauseDisabled={true}
            />
          </div>
          <div className="col-6 d-flex flex-column justify-content-center align-items-center">
            <h1 style={{ fontWeight: 700, fontSize: "30px", color: "#581845" }}>
              Oops!
            </h1>
            <p style={{ fontSize: "20px" }} className="py-3">
              Looks like the page you're searching for took a day off.
            </p>
            <p style={{ fontSize: "20px" }}>Navigate back to your Overview .</p>
            <span className="pt-3">
              <Button
                name={"Go Home"}
                variant="primary"
                onClick={() => navigate("/")}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
