import React from "react";
import styles from "./overviewdetails.module.css";
interface overviewDetails {
  callRequest: number;
  remainingCredits: number;
  creditsConsumed: number;
  OverallErrorRate:number
}

const OverViewDetails: React.FC<overviewDetails> = ({
  callRequest,
  remainingCredits,
  creditsConsumed,
  OverallErrorRate
}) => {
  return (
    <div className="card ">
      <div className="card-body">
        <div>
          <div>
            <h6 className={styles.detailsheading}>Overall Call Requests</h6>
            <h4 className={styles.detailsvalue}>{callRequest}</h4>
          </div>

          <div style={{marginTop:'35px'}}>
            <h6 className={styles.detailsheading}>Overall Credits Consumed</h6>
            <h4 className={styles.detailsvalue}>{creditsConsumed}</h4>
          </div>

          <div style={{marginTop:'35px'}}>
            <h6 className={styles.detailsheading}>Remaining Credits</h6>
            <h4 className={styles.detailsvalue}>{remainingCredits}</h4>
          </div>

          <div style={{marginTop:'35px'}}>
            <h6 className={styles.detailsheading}>Overall Error Rates</h6>
            <h4 className={styles.detailsvalue}>{OverallErrorRate}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverViewDetails;
