import * as React from 'react';
const SvgHelpIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#581845"
      d="M9.95 16q.525 0 .888-.363t.362-.887-.362-.888a1.2 1.2 0 0 0-.888-.362 1.2 1.2 0 0 0-.887.363q-.36.36-.363.887-.003.526.363.888T9.95 16m-.9-3.85h1.85q0-.825.188-1.3t1.062-1.3a7.5 7.5 0 0 0 1.025-1.238q.375-.588.375-1.412 0-1.4-1.025-2.15T10.1 4q-1.424 0-2.312.75T6.55 6.55l1.65.65q.125-.45.563-.975T10.1 5.7q.8 0 1.2.438t.4.962q0 .5-.3.938t-.75.812q-1.1.975-1.35 1.475t-.25 1.825M10 20a9.7 9.7 0 0 1-3.9-.787 10.1 10.1 0 0 1-3.175-2.138Q1.575 15.725.788 13.9A9.75 9.75 0 0 1 0 10q0-2.076.788-3.9a10.2 10.2 0 0 1 2.137-3.175A10 10 0 0 1 6.1.788 9.8 9.8 0 0 1 10 0a9.7 9.7 0 0 1 3.9.788 10.1 10.1 0 0 1 3.175 2.137A10.1 10.1 0 0 1 19.213 6.1 9.7 9.7 0 0 1 20 10a9.8 9.8 0 0 1-.788 3.9 10 10 0 0 1-2.137 3.175q-1.352 1.346-3.175 2.138A9.6 9.6 0 0 1 10 20m0-2q3.35 0 5.675-2.325T18 10t-2.325-5.675T10 2 4.325 4.325 2 10t2.325 5.675T10 18"
    />
  </svg>
);
export default SvgHelpIcon;