import React, { ReactNode, useEffect, useState } from "react";
import TooltipLite, { TooltipProps } from "react-tooltip-lite";

interface CustomTooltipProps extends TooltipProps {
  children?: React.ReactNode;
}

const TooltipComp: React.FC<CustomTooltipProps> = ({
  children,
  ...restProps
}) => {
  return <TooltipLite {...restProps}>{children}</TooltipLite>;
};

interface Props {
  title: ReactNode;
  children: React.ReactNode;
  direction?: string;
  delay?: number;
}

const CustomTooltip: React.FC<Props> = ({
  title,
  children,
  direction,
  delay = 300,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  let showTimeout: ReturnType<typeof setTimeout>;

  const handleMouseEnter = () => {
    showTimeout = setTimeout(() => {
      setIsVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(showTimeout);
    setIsVisible(false);
  };

  useEffect(() => {
    return () => clearTimeout(showTimeout);
  }, []);
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <TooltipComp
        isOpen={isVisible}
        content={title}
        direction={direction}
        arrow={false}
        distance={5}
      >
        {children}
      </TooltipComp>
    </div>
  );
};

export default CustomTooltip;
