import React from 'react'
import styles from './overviewtitle.module.css'
import { useNavigate } from 'react-router-dom';
import SvgOverviewIcon from '../../icons/SvgOverviewIcon'
import Button from '../../uikit/Button'
import SvgAddButton from '../../icons/SvgAddButton'

const OverViewTitle = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/api-products'); // Redirect to /api-products
  };
  return (
    <div className='d-flex flex-row align-items-center justify-content-between '>
      <div className='d-flex flex-row align-items-center justify-content-ceter'>
      <SvgOverviewIcon width={15} height={15} />
      <h4 className={`${styles.mainheading} mb-0 ` }>Dashboard</h4>
      </div>
     
      <Button name='API Product' svg={ <SvgAddButton/>} width={'133px'}  height={'30px'} onClick={handleButtonClick}   borderRadius="5px"/>
     
    </div>
  )
}

export default OverViewTitle