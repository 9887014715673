import React from "react";
import styles from "./apiuseage.module.css";
import MixedChart from "./MixedChart";
import Button from "../../uikit/Button";
import SvgCsvDownload from "../../icons/SvgCsvDownload";
import DatePicker from "./DatePicker";
import moment from "moment";
import RangeDatePicker from "./RangeDatePicker";
import Loader from "../../uikit/Loader";
import ApiMixedChart from "./ApiMixedChart";

interface ApiUsageData {
  lable: string[];
  day: number[];
  api_cals: number[];
  error_rate: number[];
  credits_log: number[];
}



interface ApiUseageProps {
  data: ApiUsageData;
  ResumeParser: ApiUsageData;
  AiMatchingAnalysis: ApiUsageData;
  ComparativeAnalysis: ApiUsageData;
  AiInterview: ApiUsageData;
  ProfileSummary: ApiUsageData;
  AiJdAssistance: ApiUsageData;
  JdParser: ApiUsageData;
  setDatepicker: (value: any) => void;
  ovralldays: any;
  download: () => void;
  setLoading: (value: any) => void;
  setisCurrentWeek: (value: any) => void;
  isCurrentWeek: any;
  isDatepicker: any;
  setButtonloader:(value:any)=>void;
  isButtonloader:any;
  handleShow:()=>void;
  handleHide:()=>void;
  setinnerloader?:( value:any)=>void
  innerloader?:any
}

const ApiUseage: React.FC<ApiUseageProps> = ({
  data,
  setDatepicker,
  ovralldays,
  download,
  setLoading,
  setisCurrentWeek,
  isCurrentWeek,
  isDatepicker,
  setButtonloader,
  isButtonloader,
  handleShow,
  handleHide,
  setinnerloader,
  innerloader,
  ResumeParser,
  AiMatchingAnalysis,
  ComparativeAnalysis,
  AiInterview,
  ProfileSummary,
  AiJdAssistance,
  JdParser,
}) => {
 

  const downloads = () => {
    download();
    setButtonloader(true)
  };
  return (
    <div className="card my-3">
      <div className="card-body">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h5 className={styles.mainheading}>API Usage</h5>
          </div>
          <div>
            <div className="d-flex flex-row align-items-center">
              <div>

                {/* <DatePicker
                  setDatepicker={setDatepicker}
                  setLoading={setLoading}
                  setisCurrentWeek={setisCurrentWeek}
                  isDatepicker={isDatepicker}
                  handleShow={handleShow}
                  handleHide={handleHide}
                /> */}
                <RangeDatePicker  setDatepicker={setDatepicker} setinnerloader={setinnerloader}  isDatepicker={isDatepicker} setisCurrentWeek={setisCurrentWeek}/>
              </div>
              {ResumeParser &&ResumeParser?.day?.length === 0 &&ResumeParser?.api_cals?.length === 0&&
              AiMatchingAnalysis &&AiMatchingAnalysis?.day?.length === 0 &&AiMatchingAnalysis?.api_cals?.length === 0 &&
              ComparativeAnalysis &&ComparativeAnalysis?.day?.length === 0 &&ComparativeAnalysis?.api_cals?.length === 0 &&
              AiInterview &&AiInterview?.day?.length === 0 &&AiInterview?.api_cals?.length === 0&&
              ProfileSummary &&ProfileSummary?.day?.length === 0 &&ProfileSummary?.api_cals?.length === 0 &&
              AiJdAssistance &&AiJdAssistance?.day?.length === 0 &&AiJdAssistance?.api_cals?.length === 0 &&
              JdParser &&JdParser?.day?.length === 0 &&JdParser?.api_cals?.length === 0  ? (
                ""
              ) : (
               innerloader?(''):( <div style={{ marginLeft: "7px" }}>
               {isButtonloader ? (
               <button
                 className={`btn ${styles.downloadbtnloder}`}
                 type="button"
               >
                 <span
                   className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                   role="status"
                   style={{marginBottom:'5px',width:'15px',height:'15px',borderWidth:'2px' }}
                 ></span>
               </button>
             ) : (
               <button
                 className={`btn d-flex flex-row justify-content-center align-items-center ${styles.downloadbtn}`}
                 type="button"
                 onClick={() => downloads()}
               >
                 <div className="d-flex">
                 <SvgCsvDownload />
                 </div>
                 <span className="d-flex" style={{ marginLeft: 5 }}>
                 Download
                 </span>
               </button>
             )}
             </div>)
              )}
            </div>
          </div>
        </div>
        {isCurrentWeek ? (
          <h6
            className={`${styles.subheading} m-0`}
            style={{
              padding:
               
                   "5px 0px 9px 0px"
                  
            }}
          >
            Over the last {ovralldays} days
          </h6>
        ) : (
          <h6
            className={`${styles.subheading} m-0`}
            style={{
              padding:
               
                   "5px 0px 9px 0px"
               
            }}
          >
            For the range:{" "}
            {moment(isDatepicker?.startDate).format("DD/MM/YYYY")} -{" "}
            {moment(isDatepicker?.endDate).format("DD/MM/YYYY")}
          </h6>
        )}

{innerloader?(
  <div style={{height:'300px',width:'100%'}} >
     <Loader/>
    </div>
):(
        <div style={{ height: "100%", width: "100%" }}>
          {/* <MixedChart data={data} /> */}
          <ApiMixedChart data={ComparativeAnalysis} 
          AiMatchingAnalysis={AiMatchingAnalysis}
          ResumeParser={ResumeParser}
          AiInterview={AiInterview}
          ProfileSummary={ProfileSummary}
          AiJdAssistance={AiJdAssistance}
          JdParser={JdParser}
          />
        </div>)}
      </div>
    </div>
  );
};

export default ApiUseage;
