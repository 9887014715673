import React from "react";
import { useState } from "react";
import SvgView from "../../icons/SvgView";
import SvgDownload from "../../icons/SvgDownload";
import { notify } from "../../uikit/toast";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";
import styles from "./analyticstable.module.css";

interface Analytics {
  access_count: number;
  api_name: string;
  credits_consumed: number;
  error_rate: number;
  expired_at: string; // Consider using Date if you are working with date objects
  name: string;
  no_of_request: number;
  purchased_at: string; // Consider using Date if you are working with date objects
  remaining_credits: number;
  is_download: boolean;
}

interface Charts {
  error_rate: ErrorRateEntry[];
  purchase_log: PurchaseLogEntry[];
  remaining_credits: number;
  total_credits: number;
  total_requests: number;
  weekly_api_usage: WeeklyApiUsage;
}

interface ErrorRateEntry {
  count: number;
  date: string; // Assuming the format is "DD-MM-YYYY"
  statuscode: number;
}

interface PurchaseLogEntry {
  api_name: string;
  credits: string; // Credits as a string, adjust if it should be a number
  description: string;
  download_url: string;
  email: string;
  expired_date: string; // Assuming the format is "DD-MM-YYYY"
  invoice: string;
  name: string;
  next_billing_date: string; // Assuming the format is "DD-MM-YYYY"
  paid: boolean;
  payment_date: string; // ISO 8601 date string
  total: string; // Price as a string, adjust if it should be a number
  type: string;
}

interface WeeklyApiUsage {
  label: string[];
  day: string[];
  api_calls: number[];
  error_rate: number[];
  credits_log: number[];
}

type Props = {
  valuechange?: (value: number) => void;
  setapi_name?: (value: string) => void;
  data?: Analytics[];
  updateValue?: (key: string, value: number) => void;
  setname?: (value: string) => void;
  Tabledownload?: (value: string,start:any,end:any) => void;
};

const AnalyticTable = ({
  valuechange,
  data,
  updateValue,
  setapi_name,
  setname,
  Tabledownload,
}: Props) => {
  const selectvalue = (value: string, name: string) => {
    if (valuechange && updateValue && setapi_name && setname) {
      valuechange(2);
      setapi_name(value);
      setname(name);
    }
  };

  const download = (name: string,start?:any,end?:any) => {
    if (Tabledownload) {
      Tabledownload(name,start,end);
    }
  };
 
  return (
    <>
      <div
        className="table-responsive"
        style={{ padding: " 0px 5px 0px 10px" }}
      >
        <table className={`table mb-0 ${styles.table}`}>
          <thead className={styles.stickyHeader}>
            <tr>
              <th className={styles.headingtable} style={{width:'20%'}}>API Products</th>
              <th className={styles.headingtable}>No. of Requests</th>
              <th className={styles.headingtable}>Credits Consumed</th>
              <th className={styles.headingtable}>Remaining Credits</th>
              <th className={styles.headingtable}>Error Rate</th>
              <th className={styles.headingtable}>Action </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <>
                {row.is_download ? (
                  <>
                    <tr key={index}>
                      <td
                        className={styles.name}
                        onClick={() => selectvalue(row.name, row.api_name)}
                        style={{width:'20%'}}
                      >
                        {row.name}
                      </td>
                      <td className={styles.valuetable}>
                        {row.no_of_request} Calls
                      </td>
                      <td className={styles.valuetable}>
                        {row.credits_consumed}
                      </td>
                      <td className={styles.valuetable}>
                        {row.remaining_credits}
                      </td>
                      <td className={styles.valuetable}>{row.error_rate}</td>
                      {(() => {
                        console.log("data", row);
                        return null;
                      })()}
                      <td style={{ display: "flex" }}>
                        <CustomTooltip title={"View Report"} direction="bottom">
                          <span
                            className={styles.view}
                            onClick={() => selectvalue(row.name, row.api_name)}
                          >
                            <SvgView />
                          </span>
                        </CustomTooltip>
                        <CustomTooltip
                          title={
                            row.is_download
                              ? "Download Report"
                              : "No Reports Available"
                          }
                          direction="bottom"
                        >
                          <span
                            onClick={() =>
                              row.is_download
                                ? download(row.api_name,row.purchased_at,row.expired_at)
                                : undefined
                            }
                            style={{
                              cursor: "pointer",
                              opacity: !row.is_download ? 0.5 : 1,
                            }}
                          >
                            <SvgDownload />
                          </span>{" "}
                        </CustomTooltip>
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AnalyticTable;
