import React, { useState } from "react";
import styles from "../Login/login.module.css";
import SvgZita from "../../icons/SvgZita";
import { Link, useNavigate } from "react-router-dom";
import { ForgotPasswordapi } from "../../api";
import CustomTooltip from "../../uikit/CustomTooltip/CustomTooltip";

const ForgotPassword = () => {
  const version = process.env.REACT_APP_VERSION;
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [loader, setloader] = useState<boolean>(false);
  const [emailerror, setemailError] = useState<boolean>(false);
  const [emailerrortext, setemailerrortext] = useState<string>("");

  const validateEmail = (value: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  const emailhandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setEmail(value);
    if (value.length > 50) {
      setemailerrortext("Email must not exceed 50 characters.");
    } else if (!validateEmail(value) && value.length !== 0) {
      setemailerrortext("Please enter a valid email address.");
    } else if (value.length === 0) {
      setemailerrortext("Please enter your email.");
    } else {
      setemailerrortext("");
    }
  };

  const handlesubmit = () => {
    setemailError(true);
    if (email.length === 0) {
      setemailerrortext("Please enter your email.");
    }
    if (email.length !== 0 && validateEmail(email)) {
      setloader(true);
      ForgotPasswordapi(email)
        .then((res) => {
          setloader(false);
          if (
            res?.data?.data?.message === "Password reset code sent to email."
          ) {
            sessionStorage.setItem("email", email);
            navigate("/reset-password");
          }
          if (res?.data?.success === false) {
            setemailerrortext(res.data.data.message);
          }
          //console.log("dataaa", res, typeof res.data.success);
        })
        .catch((error) => {
          if (error.response) {
          }
          if (error.response.data.success === false) {
            setemailerrortext(error?.response?.data?.data?.message);
          }
          setloader(false);
        });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handlesubmit();
    }
  };

  return (
    <div
      className={`container-fluid ${styles.bgimage}`}
      style={{
        backgroundImage: `url(/Images/loginbgimage.png)`,
        fontSize: "13px",
        height: window.innerHeight,
      }}
    >
      <div
        className="d-flex justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <div
          className="text-center"
          style={{ marginTop: "3%", width: "575px" }}
        >
          <SvgZita width={150} height={80} />
          <div style={{ marginTop: "5%" }}>
            <div>
              <h4 className="fw-bold" style={{ marginBottom: "20px" }}>
                Reset your password
              </h4>
              <p style={{ marginBottom: "0px" }}>
                {"Enter the email address associated with your account, and"}
              </p>
              <p style={{ marginBottom: "20px" }}>
                {"we'll send a verification code to reset your password."}
              </p>
              <p style={{ marginBottom: "0px" }}>
                {
                  "Please follow the instructions in the email to reset your password."
                }
              </p>
              <p style={{ marginBottom: "0px" }}>
                {
                  " If you haven't received the email within 3-5 minutes, please check your spam folder."
                }
              </p>
            </div>
            <div
              style={{ marginTop: "35px", padding: "0 87px" }}
              className="text-start"
            >
              <div>
                <label
                  htmlFor="email"
                  className="form-label"
                  style={{ fontWeight: "600" }}
                >
                  Email address
                </label>
                <input
                  style={{ height: "44px", maxWidth: "600px" }}
                  type="text"
                  className={`form-control ${styles.textbox}`}
                  id="email"
                  name="email"
                  value={email}
                  onChange={emailhandleChange}
                  onKeyDown={handleKeyDown} // Added keydown event
                />
                {emailerror && emailerrortext && (
                  <div
                    style={{
                      color: "red",
                      paddingLeft: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {emailerrortext}
                  </div>
                )}
              </div>

              <div
                style={{
                  marginTop: emailerror && emailerrortext ? "12px" : "30px",
                }}
              >
                {loader ? (
                  <button
                    className={`btn ${styles.loginbtnloder}`}
                    type="button"
                  >
                    <span
                      className={`spinner-border spinner-border-sm ${styles.loderboder}`}
                      role="status"
                      style={{ width: "15px", height: "15px" }}
                    ></span>
                  </button>
                ) : (
                  <button
                    className={`btn ${styles.loginbtn}`}
                    type="button"
                    onClick={() => handlesubmit()}
                  >
                    Continue
                  </button>
                )}
              </div>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <CustomTooltip
                    title={"Email us for support at support@zita.ai"}
                  >
                    <a
                      href="mailto:support@zita.ai?subject=Help Needed"
                      className="fw-bold "
                      style={{
                        color: "#581845",
                        textDecoration: "none",
                      }}
                    >
                      Need Help?
                    </a>
                  </CustomTooltip>
                </div>
              </div>
              <div className="d-flex justify-content-center ">
                <Link
                  to={"/"}
                  className="fw-bold mt-4"
                  style={{
                    color: "#581845",
                    textDecoration: "none",
                  }}
                >
                  Return to sign in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <footer className={`text-center ${styles.rights}`}>
          <div>App Version {version}</div>
          <div>© Sense7ai 2024 . ALL RIGHTS RESERVED</div>
        </footer>
      </div>
    </div>
  );
};

export default ForgotPassword;
