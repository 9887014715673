export default function SvgApiProductDotGray(): JSX.Element {
  return (
    <>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_78_1319)">
          <path
            d="M6.49316 12.5938C9.85865 12.5938 12.5869 9.86549 12.5869 6.5C12.5869 3.13451 9.85865 0.40625 6.49316 0.40625C3.12768 0.40625 0.399414 3.13451 0.399414 6.5C0.399414 9.86549 3.12768 12.5938 6.49316 12.5938Z"
            fill="#C7C7CB"
          />
          <path
            d="M6.09707 12.1062C9.2438 12.1062 11.7947 9.55532 11.7947 6.40859C11.7947 3.26186 9.2438 0.710938 6.09707 0.710938C2.95034 0.710938 0.399414 3.26186 0.399414 6.40859C0.399414 9.55532 2.95034 12.1062 6.09707 12.1062Z"
            fill="#D4D4D8"
          />
          <path
            d="M2.43003 3.01621C2.88707 2.29512 3.86207 1.6959 4.87769 1.51309C5.1316 1.47246 5.3855 1.45215 5.59878 1.5334C5.76128 1.59434 5.89332 1.74668 5.80191 1.91934C5.73082 2.06152 5.53785 2.12246 5.3855 2.17324C4.43278 2.48762 3.61124 3.10914 3.04957 3.94043C2.84644 4.24512 2.54175 5.08809 2.16597 4.8748C1.76988 4.64121 1.85113 3.90996 2.43003 3.01621Z"
            fill="#E8E9F0"
          />
        </g>
        <defs>
          <clipPath id="clip0_78_1319">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
