import { FC, ReactNode } from "react";

interface ButtonProps {
  name: string;
  svg?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  variant?: "primary" | "secondary" | "cancelPrimary";
  bgColor?: string;
  fontSize?: string;
  height?: string;
  width?: string;
  textColor?: string;
  borderRadius?: string;
  border?: string | "none";
  padding?: string;
  [x: string]: any; // To handle any additional props
}

const Button: FC<ButtonProps> = ({
  name,
  svg,
  onClick,
  disabled,
  type = "button",
  variant = "primary",
  bgColor,
  fontSize,
  height,
  width,
  textColor,
  borderRadius,
  border,
  padding,
  ...props
}) => {
  const baseClasses =
    "d-flex align-items-center justify-content-center  focus:outline-none transition-colors ";
  const variantClasses = {
    primary: "bg-[#581845] text-white",
    secondary: "text-[#581845]",
    cancelPrimary: "bg-[#888888] text-white cursor-not-allowed",
  };
  const customStyles = {
    backgroundColor:
      bgColor ||
      (variant === "secondary" && "#ffffff") ||
      (variant === "cancelPrimary" ? "#888888" : "#581845"),
    fontSize: fontSize ? fontSize : "13px",
    height: height ? height : "30px",
    width: width ? width : "100px",
    color: textColor || (variant === "secondary" ? "#581845" : undefined),
    border:
      border ||
      (variant === "secondary" ? "2px solid #581845" : border || "none"),
    borderRadius: borderRadius ? borderRadius : "5px",
    fontWeight: 700,
    padding: padding,
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${baseClasses}  ${variantClasses[variant]} ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
      style={customStyles}
      aria-label={name}
      {...props}
    >
      {svg && (
        <span
          className="d-flex align-items-center justify-content-center me-2"
          style={{
            width: "12px",
            height: "12px",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          {svg}
        </span>
      )}
      <span>{name}</span>
    </button>
  );
};

export default Button;
