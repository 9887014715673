import * as React from 'react';
const SvgProducts = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={13}
    fill="#1E1E1E"
    viewBox="0 0 13 13"
    {...props}
  >
    <path
      fill={props.fill}
      d="m0 6.5 2.514-2.514.805.806L1.61 6.5 3.32 8.208l-.805.806zm3.986-3.986L6.5 0l2.514 2.514-.806.805L6.5 1.61 4.792 3.32zm.806 7.167L6.5 11.39 8.208 9.68l.806.805L6.5 13l-2.514-2.514zm1.137-2.61V5.929h1.142v1.142zm3.752 1.137L11.39 6.5 9.68 4.792l.805-.806L13 6.5l-2.514 2.514z"
    />
  </svg>
);
export default SvgProducts;