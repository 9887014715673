import { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Login from "../components/Login/Login";
import Layout from "../Layout";
import ForgotPassword from "../components/Login/ForgotPassword";
import ResetPassword from "../components/Login/ResetPassword";
import ResetSuccess from "../components/Login/ResetSuccessMessage";
import SignupScreen from "src/components/Signup/Signup";
import Codeverification from "../components/Signup/Codeverification";
import Verificationsucess from "src/components/Signup/Verificationsucess";
import ProfileSummary from "../components/ProfileSummary/ProfileSummary";
import Documentation from "../components/DocumentationModal/Documentation";
import OverviewModule from "../components/OverviewModule/OverviewModule";
import Analytics from "../components/AnalyticsModal/AnalyticsScreen";
import Billing from "../components/Billing/Billing";
import ApiProducts from "../components/ApiProducts/ApiProducts";
import Authentication from "src/components/Authentication/Authentication";
import NotFound from "../components/NotFound/NotFound";

// const Overview = lazy(() =>
//   import("../components/OverviewModule/OverviewModule")
// );
// const ApiProducts = lazy(() => import("../components/ApiProducts/ApiProducts"));
// const Authentication = lazy(() =>
//   import("../components/Authentication/Authentication")
// );
// const Analytics = lazy(() =>
//   import("../components/AnalyticsModal/AnalyticsScreen")
// );
// const Billing = lazy(() => import("../components/Billing/Billing"));
// const Documentation = lazy(() =>
//   import("../components/DocumentationModal/Documentation")
// );
const AccountSetting = lazy(() =>
  import("../components/AccountSetting/AccountSetting")
);

export default function AppRoutes() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-success" element={<ResetSuccess />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/sign-up" element={<SignupScreen />} />
        <Route path="/code-verification" element={<Codeverification />} />
        <Route path="/verification-success" element={<Verificationsucess />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<OverviewModule />} />
          <Route path="/api-products" element={<ApiProducts />} />
          <Route path="/authentication" element={<Authentication />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/account-setting" element={<AccountSetting />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/api-products/:id" element={<ProfileSummary />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
}
