
import * as React from "react";
const SvgNoReport = (props: any) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 14H6V9H4V14ZM12 14H14V4H12V14ZM8 14H10V11H8V14ZM8 9H10V7H8V9ZM2 18C1.45 18 0.97917 17.8042 0.5875 17.4125C0.19583 17.0208 0 16.55 0 16V2C0 1.45 0.19583 0.97917 0.5875 0.5875C0.97917 0.19583 1.45 0 2 0H16C16.55 0 17.0208 0.19583 17.4125 0.5875C17.8042 0.97917 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM2 16H16V2H2V16Z"
      fill="#999FAC"
    />
  </svg>
);
export default SvgNoReport;
