import { configureStore } from '@reduxjs/toolkit'
import accountSettingReducer  from '../reducers/AccountSettingSlice'
import ChangePasswordReducer from '../reducers/ChangePasswordSlice'
import DocumentationReducer from '../reducers/Documentation'
import authenticationReducer from '../reducers/AuthenticationSlice'
import BillingReducer from '../reducers/BillingSlice' 
import ApiProductsResucer from "../reducers/ApiProductsSlice"

export const store = configureStore({
  reducer: {
    accountSetting : accountSettingReducer,
    ChangePassword : ChangePasswordReducer,
    DocumentationData:DocumentationReducer,
    authentication : authenticationReducer,
    Billing  : BillingReducer,
    ApiProducts : ApiProductsResucer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch