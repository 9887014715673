import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {  applicationapi } from "../../api";


// interface data {
//     data : ResponseItemType,
//     success : boolean
// }

// interface PlanType {
//     id: number;
//     requests: number;
//     validity: string | number;
//     credits: number;
//     price: string;
//     stripe_id: string;
//   }
  
//   interface ResponseItemType {
//     Active: boolean;
//     File_format: [];
//     Plans: [];
//     Resume_size: number;
//     addon: PlanType;
//     api_name: string;
//     description: string;
//     is_active: boolean;
//     monthly: PlanType;
//     name: string;
//     version: string;
//     yearly: PlanType;
//     url: string;
//   }


const initialState  = {
 name : "getApiProducts",
 isLoading : false,
 isError : false,
 data : []
}

export const getApiProducts = createAsyncThunk<any>('getApiProducts', async ()=>{
    const idToken = sessionStorage.getItem("id_token");
    const url = '/apiproducts'
    try {
        const response = await applicationapi.get(url, {
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            }}
        )      
        return response.data       
    } catch (error) {
        console.log(error);     
    }
})


export const selectProductById = (store : any, itemId: any) => {
    return store.ApiProducts.data?.find((item :any) => String(item.id) === String(itemId) );
  };

export const handelCheckoutApi = createAsyncThunk<any, any>('handelCheckoutApi', async (formData)=>{
    const idToken = sessionStorage.getItem("id_token");
    const url = '/checkout_session'
    // console.log('formData---->', formData);
    try {
        const response = await applicationapi.post(url,formData,
            {
                headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
                }
            }
        )  
        return response.data     
    } catch (error) {
        console.log(error);     
    }
})

export const varifiedCheckoutApi = createAsyncThunk<any, any>('varifiedCheckoutApi', async(session_id)=>{
 const idToken = sessionStorage.getItem("id_token");
    const url = '/retrieve_session'
    //console.log(session_id);
    try {
        const response = await applicationapi.put(url,
            session_id,
            {
                headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
                }
            }
        )  
        return response.data     
    } catch (error) {
        console.log(error);     
    }
})

const ApiProductsSlice = createSlice({
    name : "ApiProductsSlice",
    initialState,
    reducers : {},
    extraReducers :(builder)=>{
        builder
        .addCase(getApiProducts.pending, (state)=>{
            state.isLoading = true
        })
        .addCase(getApiProducts.fulfilled, (state, action: PayloadAction<any>)=>{
            state.data = action?.payload?.data?.response
            state.isLoading = false
        }).addCase(getApiProducts.rejected, (state)=>{
            state.isLoading = false
            state.isError= true
        })
    }
})

export default ApiProductsSlice.reducer