import * as React from 'react';
const SvgAuthenticate = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="#1E1E1E"
    viewBox="0 0 12 13"
    {...props}
  >
    <path
      fill={props.fill}
      d="M6.906 5.687a1.22 1.22 0 0 1-.812 1.15V8.53a.406.406 0 0 1-.813 0V6.837a1.219 1.219 0 1 1 1.625-1.15M5.912.068a.41.41 0 0 0-.45 0A12.2 12.2 0 0 1 .35 2.035.406.406 0 0 0 0 2.437v3.656c0 3.162 1.874 5.469 5.541 6.88a.4.4 0 0 0 .293 0c3.666-1.412 5.54-3.719 5.54-6.88V2.437a.41.41 0 0 0-.349-.402A12.2 12.2 0 0 1 5.912.068m-5.1 2.717a13 13 0 0 0 4.55-1.692l.325-.202.325.202a13 13 0 0 0 4.55 1.692v3.308c0 2.756-1.599 4.764-4.875 6.064C2.411 10.857.812 8.849.812 6.093z"
    />
  </svg>
);
export default SvgAuthenticate;