import React from "react";
import styles from "./overviewdetails.module.css";
import moment from "moment";
interface RequestState {
  no_of_request: number;
  credits_consumed: number;
  remaining_credits: number;
 
}
type Props = {
  data?: RequestState;
  fromdate?:any;
  todate?:any;
};
  const OverViewDetails = ({ data ,fromdate,todate}: Props) => {
    return (
      <div className="card my-3">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-4 mb-3 mb-md-0">
              <div
                className={`${styles.customBorder}  d-none d-md-block pe-md-3`}
              >
                <h6 className={styles.detailsheading}>Call Requests</h6>
                <p className={styles.detailssubheading}>
                For the range:{" "}
                  {moment(fromdate).format("DD/MM/YYYY")} -{" "}
                  {moment(todate).format("DD/MM/YYYY")}
                </p>
                <h4 className={styles.detailsvalue}>{data?.no_of_request}</h4>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-3 mb-md-0">
              <div
                className={`${styles.customBorder}  d-none d-md-block pe-md-3`}
              >
                <h6 className={styles.detailsheading}>Credits Consumed</h6>
                <p className={styles.detailssubheading}>
                For the range:{" "}
                  {moment(fromdate).format("DD/MM/YYYY")} -{" "}
                  {moment(todate).format("DD/MM/YYYY")}
                </p>
                <h4 className={styles.detailsvalue}>
                  {data?.credits_consumed}
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div>
                <h6 className={styles.detailsheading}>Remaining Credits</h6>
                <p className={styles.detailssubheading}>
                  Available with Subscription
                </p>
                <h4 className={styles.detailsvalue}>{data?.remaining_credits}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default OverViewDetails;
