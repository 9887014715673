const SvgEyeClose: React.FC = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_53_7206)">
        <path
          d="M12.5 9.99967C12.5 11.378 11.3783 12.4997 10 12.4997C8.62167 12.4997 7.5 11.378 7.5 9.99967C7.5 8.62134 8.62167 7.49967 10 7.49967C11.3783 7.49967 12.5 8.62134 12.5 9.99967ZM20 9.62551C20 9.62551 16.4567 16.6663 10.0125 16.6663C4.02917 16.6663 0 9.62551 0 9.62551C0 9.62551 3.705 3.33301 10.0125 3.33301C16.4242 3.33301 20 9.62551 20 9.62551ZM14.1667 9.99967C14.1667 7.70217 12.2975 5.83301 10 5.83301C7.7025 5.83301 5.83333 7.70217 5.83333 9.99967C5.83333 12.2972 7.7025 14.1663 10 14.1663C12.2975 14.1663 14.1667 12.2972 14.1667 9.99967Z"
          fill="#581845"
        />
        <g clipPath="url(#clip1_53_7206)">
          <path
            d="M9.90417 12.4903L12.4908 9.90866L12.5 10.0003C12.5 11.3787 11.3783 12.5003 10 12.5003L9.90417 12.4903ZM16.6108 5.79699L13.8825 8.52033C14.0592 8.98199 14.1667 9.47783 14.1667 10.0003C14.1667 12.2978 12.2975 14.167 10 14.167C9.47417 14.167 8.97583 14.0587 8.5125 13.8803L6.53167 15.857C7.595 16.347 8.7575 16.667 10.0125 16.667C16.4567 16.667 20 9.62616 20 9.62616C20 9.62616 18.8208 7.56116 16.6108 5.79699ZM17.8033 2.85033L2.2875 18.3337L1.13583 17.1487L4.015 14.2762C1.49667 12.2345 0 9.62616 0 9.62616C0 9.62616 3.705 3.33366 10.0125 3.33366C11.5333 3.33366 12.8925 3.68866 14.0842 4.22949L16.6517 1.66699L17.8033 2.85033ZM6.34417 11.952L7.61 10.6895C7.54583 10.4695 7.5 10.2412 7.5 10.0003C7.5 8.62199 8.62167 7.50033 10 7.50033C10.2425 7.50033 10.4725 7.54616 10.6942 7.61199L11.9592 6.34949C11.3725 6.03116 10.7125 5.83366 10 5.83366C7.7025 5.83366 5.83333 7.70283 5.83333 10.0003C5.83333 10.7103 6.02917 11.3678 6.34417 11.952Z"
            fill="#581845"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_53_7206">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_53_7206">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgEyeClose;
